import { combineReducers  } from 'redux';
import _ from 'lodash';
import qs from 'query-string';

const queryString = qs.parse(window.location.search, { ignoreQueryPrefix: true, decode: false });

const compCode = queryString.CID ? queryString.CID : process.env.REACT_APP_BLESS_HOSPITAL_COMPCODE;

const compCodeReducer = (state=compCode, action) => {              // Holds Company code of current company.
  if (action.type === 'PARTY_CODE') {
    return action.value;
  }
  return state;
}

const loginModalReducer = (loginModalOpen={status: false, mode: 'PATIENT'}, action) => {             // Holds Status (open/close) of Login modal.
  if (action.type === 'LOGIN_MODAL') {
    return action.payload;
  }
  return loginModalOpen;
}

const loginStatusReducer = (isLoggedIn=false, action) => {                  // Holds Status (open/close) of Login modal.
  if (action.type === 'LOGIN') {
    return action.value;
  }
  return isLoggedIn;
}

const loaderReducer = (isLoading=false, action) => {                        // Holds Status (open/close) of Global Loader on app.js page.
  if (action.type === 'LOADING') {
    return action.value;
  }
  return isLoading;
}

const bookingModalReducer = (bookingModalOpen=false, action) => {           // Controls Booking modal on footer.js page.
  if (action.type === 'BOOKING_MODAL') {
    return action.value;
  }
  return bookingModalOpen;
}

let currentUser = {
  Salutation: '',
  Name: '',
  EncCompanyId: '',
  PartyCode: '',
  RegMob1: '',
  Gender: '',
  GenderDesc: '',
  Address: '',
  Age: '',
  AgeMonth: '',
  AgeDay: '',
  UserPassword: '',
  Department: {dName: 'All', SubCode: 0},
  UserType: 'PATIENT',
  Qualification: '',
  RegNo: '',
  SpecialistId: 0,
  MPartyCode: '',
  PartyId: 0,
  selectedCompany: {},
  MembersList: {},                        // we'll not store it in localstorage and will retrieve it using api when user logs in.
  selectedMember: {},
  UserId: 0,
  location: {},

  State: '3',
  City: '',
  Pin: '',
  Address2: '',

  DOB: '',
  AnniversaryDate: '',
  Aadhaar: '',
  IsDOBCalculated: 'N',

  compName: '',
  compAddress: '',
  compState: '',
  compPin: '',
  compPhone1: '',
  compPhone2: '',
  compMail: '',
}

const userInfoReducer = (state=currentUser, action) => {                // Holds all the Informations of User.
  if (action.type === 'USER_INFO') {                                    // *** OldState === newState turns out to be true which causes components to not rerender on change of deep nested properties 
    let newState = Object.assign(state, action.payload);                // or on change of properties that are without any key on them (eg. list of objects without keys).
    return {...newState};                                               // {...newState} or _.deepClone(newState) will clone the given object/state and reproduce a new object/state where           
  }                                                                     // oldState !== {...newState} or _.deepClone(newState) which forces every connected component to re-render with new state.          
  return state;                                                                                                                                                                  
}

const init_compInfo = {COMPNAME: 'BLESS HOSPITAL', EncCompanyId: '4K%2Bip4H91KicEh1TMAw9Rw==', LogoUrl: '636.jpg'};

const companyInfoReducer = (state=init_compInfo, action) => {              // Holds the Information of current Company.
  if (action.type === 'COMPANY_INFO') {
    return action.payload;
  }
  return state;
}

const bookingDataReducer = (state={}, action) => {
  if (action.type === 'BOOKING_DATA') {                                 // *** OldState === newState turns out to be true which causes components to not rerender on change of deep nested properties 
    let newState = Object.assign(state, action.payload);                // or on change of properties that are without any key on them (eg. list of objects without keys).
    return {...newState};                                               // {...newState} or _.deepClone(newState) will clone the given object/state and reproduce a new object/state where           
  }                                                                     // oldState !== {...newState} or _.deepClone(newState) which forces every connected component to re-render with new state.          
  return state;  
}

const siteDataReducer = (state={itemMasterCollection: ['loading'], ParentCategoryList: [{Value: ''}], LinkSubCategoryList: []}, action) => {
  if (action.type === 'SITE_DATA') {
    return action.payload;
  } else {
    return state;
  }
}

const cartReducer = (cart={}, action) => {                            // Holds Cart items data.
  if (action.type==='ADD_ITEM') {
    return {...cart, [action.payload.ItemId]: action.payload };
  } else if (action.type==='REMOVE_ITEM') {
    return _.omit(cart, action.payload);                             // Pass list and id of target item to remove it from list.
  } else if (action.type==='DUMP_CART') {
    return {};
  }
  return cart;
}

const orderReducer = (myOrders={}, action) => {                            // Holds Cart items data.
  if (action.type==='ADD_ORDER_ITEM') {
    return {...myOrders, [Math.trunc(action.payload.Amount)]: action.payload };
  } else if (action.type==='REMOVE_ORDER_ITEM') {
    return _.omit(myOrders, Math.trunc(action.payload.Amount));                             // Pass list and id of target item to remove it from list.
  } else if (action.type==='DUMP_ALL_ORDER_ITEMS') {
    return {};
  }
  return myOrders;
}

const toastReducer = (state={status: false, item: {}}, action) => {       // Controls the Toast state and data.
  if (action.type === 'SHOW_TOAST') {
    console.log(action.payload);
    return action.payload;
  }
  return state;
}

const modals = {                                                  // state of all modals.
  SCHEDULE_MODAL: {state: false, data: ''},
  MEMBER_MODAL: {state: false, data: ''},
  DOC_DETAILS_MODAL: {state: false, data: ''}
};

const modalReducer = (state=modals, action) => {  
  if (action.type === 'MODAL') {
    return {...state, [action.payload.name]: {state: action.payload.status, data: action.payload.data}};
  }    
  return state;  
}

const IsMobileReducer = (isMobile=false, action) => {                  // Holds Status of viewving device.
  if (action.type === 'IS_MOBILE') {
    return action.value;
  }
  return isMobile;
}

export default combineReducers({
  compCode: compCodeReducer,
  loginModalOpen: loginModalReducer,
  bookingModalOpen: bookingModalReducer,
  isLoggedIn: loginStatusReducer,
  userInfo: userInfoReducer,
  isMobile: IsMobileReducer,
  compInfo: companyInfoReducer,
  isToastActive: toastReducer,
  isLoading: loaderReducer,
  cart: cartReducer,
  orders: orderReducer,
  siteData: siteDataReducer,
  modals: modalReducer,
  bookingInfo: bookingDataReducer
})

import React from 'react';
import { Link } from 'react-router-dom';
import { addToCartAction, addToWishlistAction, toastAction, modalAction, quickviewItemAction, removeFromWishlistAction, removeFromCartAction } from './../../actions';
import { connect } from 'react-redux';
import { updateLocalStorageItems } from '../utilities';


const ProductCardM = ({ data, modalAction, quickviewItemAction, addToWishlistAction, addToCartAction, toastAction, removeFromCartAction, removeFromWishlistAction}) => {

  return (
    <div className='mobile-product-card'>
        <div className='image-box'>
          <Link to={`/productPage/${data.ItemId}`}>
            <img src={data.ItemImageURL} alt={data.Description}/>
          </Link>
        </div>
        <div className='content-box'>
            <h5>{data.Description}</h5>
        </div>
        <span className='mobile-price-tag'>{Math.trunc(data.Discount)}%<br/>off</span>
    </div>
  )
}


const mapStateToProductsCard = (state) => {
  return {};
}

export const ConnectedProductCardM = connect(mapStateToProductsCard, {modalAction, quickviewItemAction, addToWishlistAction, addToCartAction, toastAction, removeFromCartAction, removeFromWishlistAction})(ProductCardM);


const CartCardM = ({ data, removeFromCartAction, addToWishlistAction, addToCartAction }) => {
  return (
    <div className='mobile-cart-card'>
      <div className='cart-content d-flex'>
        <div className='cart-image'>
          <Link to={`/productPage/${data.ItemId}`}>
            <img src={data.ItemImageURL} alt="cart-item" />
          </Link>
        </div>
        <div className='cart-item-details'>
          <Link to={`/productPage/${data.ItemId}`}>
            <h6>{data.Description}</h6>
          </Link>
          <div className='d-flex flex-wrap'> 
             <div className='col-price'>Price</div>
             <div className='col-quantity'>Quantity</div>
             <div className='col-total'>Total</div>
             <div>₹ {data.SRate}</div>
             <div>
                <i className='bx bx-plus-circle me-3' onClick={() => {addToCartAction({...data, count: parseInt(data.count + 1)}); updateLocalStorageItems()}} style={{fontSize: '1.8rem', verticalAlign: 'middle'}}></i> 
                  {data.count} 
                <i className='bx bx-minus-circle ms-3' onClick={() => {if (data.count !== 1) addToCartAction({...data, count: parseInt(data.count - 1)}); updateLocalStorageItems()}} style={{fontSize: '1.8rem', verticalAlign: 'middle'}}></i>
             </div>
             <div>₹ {(data.count * data.SRate).toFixed(2)}</div>
          </div>
        </div>
      </div>
      <div className='cart-buttons'>                                             {/* no need to update cart item count because it will already have count property when it get added as cart items */}
        <button type='button' onClick={() => {removeFromCartAction(data.ItemId); addToWishlistAction(data); updateLocalStorageItems();}} className='button add-cart-button'><i className="bx bxs-heart"></i> Move to wishlist</button>
        <button type='button' onClick={() => {removeFromCartAction(data.ItemId); updateLocalStorageItems();}} className='button add-wishlist-button'><i className="bx bxs-trash-alt"></i> Remove from cart</button>
      </div>
    </div>
  )
}

const mapStateToCartCard = (state) => {
  return {};
}

export const ConnectedCartCardM = connect(mapStateToCartCard, {addToWishlistAction, toastAction, removeFromCartAction, addToCartAction})(CartCardM);


const WishlistCardM = ({ data, removeFromWishlistAction, addToCartAction }) => {
  return (
    <div className='mobile-cart-card'>
      <div className='cart-content d-flex'>
        <div className='cart-image'>
          <Link to={`/productPage/${data.ItemId}`}>
            <img src={data.ItemImageURL} alt="cart-item" />
          </Link>
        </div>
        <div className='cart-item-details'>
          <Link to={`/productPage/${data.ItemId}`}>
            <h6>{data.Description}</h6>
          </Link>
          <div className='d-flex flex-wrap'> 
             <div className='col-price'>Price</div>
             <div className='col-quantity'>Quantity</div>
             <div className='col-total'>Total</div>
             <div>₹ {data.SRate}</div>
             <div>{data.count}</div>
             <div>₹ {(data.count * data.SRate).toFixed(2)}</div>
          </div>
        </div>
      </div>
      <div className='cart-buttons'>
        <button type='button' onClick={() => {removeFromWishlistAction(data.ItemId); updateLocalStorageItems();}} className='button add-cart-button'><i className="bx bxs-trash-alt"></i> Remove from wishlist</button>
        <button type='button' onClick={() => {addToCartAction(data); removeFromWishlistAction(data.ItemId); updateLocalStorageItems();}} className='button add-wishlist-button'><i className='bx bxs-cart-alt'></i> Move to cart</button>
      </div>                                 {/* no need to update cart item count because it will already have count property when it get added as wishlist items */}
    </div>
  )
}

const mapStateToWishlistCardM = (state) => {
  return {};
}

export const ConnectedWishlistCardM = connect(mapStateToWishlistCardM, {addToCartAction, toastAction, removeFromWishlistAction})(WishlistCardM);


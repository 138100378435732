import { HashRouter, Route, Switch } from 'react-router-dom';
import './CSS/animations.css';
// import './CSS/styles2New.css';
import './CSS/styles.css';
import './CSS/mobileViewStyles.css';
import './CSS/responsive.css';
import './CSS/react-datepicker.css';
import './CSS/fontawesome/css/fontawesome.min.css';
import './CSS/fontawesome/css/all.min.css';
import './CSS/fancyboxNew.css';
import './CSS/slick.css';
import './CSS/venobox.css';
import './CSS/icofont/icofont.min.css';
// import './CSS/chosen.min.css';

import Header from './header';
import Home from './home';
import Specialists from './specialists';
import Footer from './footer';
import Pharmacy from './test';
import LabTests from './labTests';
import BottomNav from './bottomNav';
import CartPage from './cartPage';
import Dashboard from './dashboards';
import Profile from './profiles';
import ScrollToTop from '../ScrollToTop.js';
import DoctorProfile from './profiles/doctorProfile';
import { connect } from 'react-redux';

import useScript, { ConnectedIsMobile, ConnectedToast, NotFound, useStylesheet, useOnlineStatus, WifiLoader } from './utilities';
import ProductPage from './productPage';
import myOrders from './myOrders';
import { useState } from 'react';
import Services from './services';
import { useEffect } from 'react';
import PatientQueue from './patientQueue';
import Modals from './modals';
import Prescription from './prescription.jsx';
import InvoicePrint from './invoicePrint.jsx';
import BedStatus from './bedStatus.jsx';
import Authentication from './auth.jsx';

function App({ isLoading, compCode }) {

  const [stylePath, setStylePath] = useState('');
  const isOnline = useOnlineStatus();
  // const [compStylePath, setCompStylePath] = useState('');

  useEffect(() => {
    if (compCode === 'MjLxadrssyExUU7EojuDtw==') {
      setStylePath('css/am-nursing/styles.css');
    }
  },[compCode, stylePath])



  useScript('js/bootstrap.min.js');
  useStylesheet(stylePath);

  // https://preview.themeforest.net/item/gull-bootstrap-laravel-admin-dashboard-template/full_screen_preview/23101970?_ga=2.244119106.691121769.1689078001-895583682.1675239317
  
  return (
    <div className='app-container'>
      <HashRouter>
        {/* <Theme/> */}
        {isLoading ? <div className='spinner-container'><GlobalLoader/></div> : ''}
        {/* <i className="bx bx-search header-login ms-3" onClick={() => setStylePath('css/themes/theme1.css')}></i> */}
        <Header/>
        <Authentication/>
        {/* <Menu/> */}
        <BottomNav/>
        {!isOnline && <WifiLoader/>}
        <ScrollToTop/>
        <div className='app-content'>
          <Switch>
            <Route path='/' exact component={Home}/>
            <Route path='/specialists' component={Specialists}/>
            <Route path='/pharmacy' component={Pharmacy}/>
            <Route path='/labTests' component={LabTests}/>
            <Route path='/cartPage' component={CartPage}/>
            <Route path='/dashboard' component={Dashboard}/>
            <Route path='/doctorProfile/:id' component={DoctorProfile}/>
            <Route path='/productPage/:id' component={ProductPage}/>
            <Route path='/myOrders' component={myOrders}/>
            <Route path='/services' component={Services}/>
            <Route path='/patientQueue' component={PatientQueue}/>
            <Route path='/prescription/:id' component={Prescription}/>
            <Route path='/invoicePrint/:id' component={InvoicePrint}/>
            <Route path='/bedStatus' component={BedStatus}/>
            <Route path='/profile' component={Profile}/> 
            <Route path='/*' component={NotFound}/>        
            {/* <Route path='/test' component={MyCarousel}/> */}
          </Switch>
        </div>
        <Footer/>
        <Modals/>
        <ConnectedToast/>
        <ConnectedIsMobile/>
      </HashRouter>
    </div>
  );
}

const mapStateToProps = (state) => {
  return { isLoading: state.isLoading, compCode: state.compCode };
}

export default connect(mapStateToProps, {})(App);


// const PrintPage = () => {                      // to be deleted.

//   useEffect(() => {
//     window.print();
//   },[])

//   return (
//     <Prescription/>
//   )
// }


const GlobalLoader = () => {
  return (
    <div className='spinner-box' style={{minHeight: '100vh'}}>
      <div className="wrapper">
        <div className="circle"></div>     
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="shadow"></div>
        <div className="shadow"></div>
        <div className="shadow"></div>
      </div>
    </div> 
  )
}
import Modal from 'react-bootstrap/Modal';
import { QueueCard } from './cards';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

const PatientQueue = ({ compCode, userInfo }) => {

    const [list, setList] = useState([]);

    // useEffect(() => {

    //     let controller = new AbortController();                       // To avoid race condition. axios docs.

    //     const getQueueList = async (companyCode) => {
    //         try {
    //             const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/Values?CID=${companyCode}&DID=0&DeptId=8636`, { signal: controller.signal } );
    //             if (res.status === 200) {
    //                 setList(res.data);
    //             } else if (res.status === 500) {                       // res code 500 is treated as response not error hence need to handle it manually.
    //                 alert('Error : 500. Something went wrong.');
    //             }
    //         } catch (err) {
    //             alert(err.message);
    //         }
    //     }
    //     var timer;
    //     const refreshQueueList = () => {
    //         timer = setInterval(() => {
    //             getQueueList(userInfo.selectedCompany.EncCompanyId);
    //         }, 15000);
    //     }

    //     getQueueList(userInfo.selectedCompany.EncCompanyId);
    //     refreshQueueList();

    //     return () => {
    //         clearInterval(timer);                       // clear the setInterval function to stop making further request on page unmount.
    //         controller.abort();                         // this will cancel the previous api request so that new request can be made avoiding the race condition.
    //     }
    // },[compCode])

    useEffect(() => {
        const getQueueList = async (companyCode) => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/Values?CID=${companyCode}&DID=0&DeptId=8636`, { signal: AbortSignal.timeout(15000) } );
                if (res.status === 200) {
                    setList(res.data);
                } else if (res.status === 500) {                       // res code 500 is treated as response not error hence need to handle it manually.
                    alert('Error : 500. Something went wrong.');
                }
            } catch (err) {
                if (err.code === 'ERR_CANCELED') return; 
                alert(err.message);
            }
        }
        var timer;
        const refreshQueueList = () => {
            timer = setInterval(() => {
                getQueueList(userInfo.selectedCompany.EncCompanyId);
            }, 15000);
        }

        getQueueList(userInfo.selectedCompany.EncCompanyId);
        refreshQueueList();

        return () => {
            clearInterval(timer);                       // clear the setInterval function to stop making further request on page unmount.
        }
    },[compCode])
    
    
    const  renderQueueModal = () => {
        return (
            <section>
                {list.map(i => (<QueueCard data={i} key={i.TokenNo} />))}
                {list.length === 0 && <div className='text-center my-5 w-100'><h2 className="text-info mark">No Data Found.</h2></div>}
            </section>
        )
    }
    
    return (
        <Modal show={true} fullscreen={true} id="queue-modal">
            <Modal.Body>{renderQueueModal()}</Modal.Body> 
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return { compCode: state.compCode, userInfo: state.userInfo };
}

export default connect(mapStateToProps, {})(PatientQueue);
import { navigateToDashboard } from "../utilities";
import { connect } from "react-redux";


const Dashboard = ({ userInfo, isLoggedIn }) => {
    if (!isLoggedIn) return alert('Please login to view Dashboard.');
    return navigateToDashboard(userInfo.UserType);

}

const mapStateToDashboard = (state) => {
    return { userInfo: state.userInfo, isLoggedIn: state.isLoggedIn }
}

export default connect(mapStateToDashboard, {})(Dashboard);

// import qs from 'query-string';
import { getFrom } from '../utilities';
import { connect } from 'react-redux';
import { modalAction } from '../../actions';
import { MemberCard } from '../cards';


const PatientProfile = ({ match, userInfo, compCode, modalAction }) => {

    // const queryString = qs.parse(window.location.search, { ignoreQueryPrefix: true, decode: false });

    // const [doctorData, setDoctorData] = useState({loading: true, data: [], err: {status: false, msg: ''}});

    // useEffect(() => {
    //     if (queryString.CID) {
    //         getDoctorData(queryString.CID);
    //         } else {
    //         getDoctorData(userInfo.selectedCompany.EncCompanyId);
    //     }
    // }, [queryString.CID, userInfo.selectedCompany.EncCompanyId])

    // const getDoctorData = async (companyCode) => {
	// 	if (!companyCode) return;
	// 	const res = await getFrom(`${process.env.REACT_APP_BASE_URL}/api/Values?CID=${companyCode}&DID=${match.params.id}`, {}, setDoctorData);
	// 	if (res) {
	// 	  setTimeout(() => {
	// 		console.log(res);
	// 		setDoctorData(res);  
	// 	  }, 1000)
	// 	}                                                                                                   
	// } 

    return (
        <div className="container-fluid pt-3">
            <div className="row">
                <div className="col-12 dct-appoinment">
                    <div className="card">
                        <div className="card-body">
                            <div style={{ fontSize: "0.8em", marginBottom: "1.3em" }}>
                                <div className="dashboard-card dashboard-card-user_card">
                                    <div className="dashboard-card__img-box">
                                        <img style={{maxHeight: '5.7em'}} src="/img/user_unknown.png" alt="User" />
                                        <div className="img">
                                            <a title="Prakash Verma" href="#/dashboard"> {userInfo.Name} </a>
                                            <span>UHID : {userInfo.UHID}</span>
                                        </div>
                                    </div>
                                    <div className="dashboard-card__content-box">
                                        <p>
                                            <span>
                                                <i className="bx bxs-phone-call" /> Phone :
                                            </span>
                                            <span> +91 {userInfo.RegMob1}</span>
                                        </p>
                                        <p>
                                            <span>
                                                <i className="bx bxs-hourglass-bottom" /> Age :
                                            </span>
                                            <span> {userInfo.Age} Years</span>
                                        </p>
                                        <p>
                                            <span>
                                                <i className="bx bx-male-female" /> Gender :
                                            </span>
                                            <span>{userInfo.GenderDesc}</span>
                                        </p>
                                        <p className="mb-0">
                                            <span>
                                                <i className="bx bxs-map" /> Address :
                                            </span>
                                            <span>{userInfo.Address}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card card-table mb-0">
                                <div className="card-body">
                                    <div className="appointment-tab">
                                        <ul className="nav nav-tabs nav-tabs-solid nav-tabs-rounded p-3 pe-0">
                                            <li className="nav-item">
                                                <a className="nav-link active" href="#"> Members </a>
                                            </li>
                                            {/* <li className="nav-item mx-2">
                                                <a className="nav-link " href="#"> Tab 2 </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link " href="#"> Tab 3 </a>
                                            </li> */}
                                        </ul>
                                        <div className="tab-content">
                                            <div className="tab-pane fade show active" id="previous-appointments" >
                                                <div className="card card-table mb-0 border-0" style={{boxShadow: 'none'}}>
                                                    <div className="card-body">
                                                        <div className="dashboard-card__btn-box justify-content-between w-100 align-items-center" style={{fontSize: '1.5em'}}> 
                                                            <h4 className="card-title mb-0">Family Members</h4>                                                      
                                                            <button onClick={() => modalAction('MEMBER_MODAL', true)} className='dashboard-card__btn-box-item reverse-hover d-flex align-items-center icon-btn' style={{'--clr': '#48fffc3b', '--bg': '#149A8D', '--bClr': '#149a8d57', gap: '0.3em', fontSize: '0.8em', padding: '0.5em 0.6em 0.3em'}}><i className='bx bx-plus-circle'></i> Add New Member</button>
                                                        </div>
                                                        {userInfo.MembersList.AccPartyMemberMasterList?.map(item => (
                                                            <MemberCard key={item.MemberId} data={item} modalAction={modalAction} />
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToPatientProfile = (state) => {
    return { compCode: state.compCode, userInfo: state.userInfo };
}
  
export default connect(mapStateToPatientProfile, { modalAction })(PatientProfile);
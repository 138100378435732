import { useState, useEffect, useRef } from "react";
import { ButtonSlider, CompanySlider, ModalComponent, getFrom } from './utilities';
import { connect } from 'react-redux';
import { bookingModalAction, loaderAction, loginModalAction, loginStatusAction, userInfoAction, modalAction, bookingInfoAction } from './../actions';
import axios from 'axios';
// import RenderCarousel from "./carousel";
import { Link, useHistory } from "react-router-dom";
import CountUp from 'react-countup';
import Skeleton from "react-loading-skeleton";


function Footer({ compInfo, bookingModalOpen, bookingModalAction, userInfo, userInfoAction, isMobile }) {

  const companyLogo = ['598','608','612','635','636','637','639','620', '659', '680'];
  const [refNo, setRefNo] = useState('null');
  const [refActive, setRefActive] = useState(false);
  const [locationOverlay, setLocationOverlay] = useState(true);
  const [dropdownActive, setDropdownActive] = useState(false);

  const showReference = () => {
    return (
      <div>
        <span className="text-info">Please keep your Reference No: <span className='text-danger ms-2'>{refNo}</span></span>
        <span className="d-flex justify-content-end">
          <CountUp start={30} end={0} duration={5} delay={0} separator=" " prefix="Closing in  " suffix=" seconds" onEnd={() => setRefActive(false)} >
            {({ countUpRef, start }) => (
              <div>
                <span ref={countUpRef} />
                <button onClick={start}></button>
              </div>
            )}
          </CountUp>
        </span>
      </div>
    )
  }

  function getLocation() {
    if (userInfo.location.latitude) return;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function(position) {
        userInfoAction({ location: position.coords });
        setLocationOverlay(false);
      },
      function(err) {
        console.log(err.message);
      });                
    } else {
      console.log("Not Available");
    }
  }
  
  useEffect(() => {
    setTimeout(() => {        
      getLocation();      
    }, 3000);
  }, [userInfoAction])

  return (
    <>
      {locationOverlay && <span onClick={() => {getLocation(); setLocationOverlay(false);}} style={{position: 'fixed', inset: 0, zIndex: 1111 }}></span>}   {/* background: '#f0f8ff7d' */} 
      {/* {locationOverlay && <div className="modal fade show d-block" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{background: '#00000057'}}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-info fw-bold" id="exampleModalLabel">GET NEARBY CLINICS</h5>
              <Link className="btn-close" to='#' onClick={() => setLocationOverlay(false)} aria-label="Close" ></Link>
            </div>
            <div className="modal-body">
              View nearby clinics only.
            </div>
            <div className="modal-footer">
              <Link to='/' className="btn btn-primary" data-dismiss="modal" onClick={() => {getLocation(); setLocationOverlay(false);}}> YES PLEASE </Link>
              <Link to='/' className="btn btn-primary" data-dismiss="modal" onClick={() => setLocationOverlay(false)}> NO THANKS </Link>
            </div>
          </div>
        </div>
      </div>} */}
      <footer className="footer">
        <div className="footer-top">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-widget footer-about">
                            <div className="footer-logo">
                                {
                                  companyLogo.includes(compInfo.LogoUrl.split('.')[0]) ? 
                                  <img className="logo" src={`/img/logo/${compInfo.LogoUrl}`} alt={compInfo.COMPNAME} style={{height: '3.5rem'}}/> :
                                  <img src={'/img/logo/opd2.png'} className="img-fluid logo rounded-circle" alt={compInfo.COMPNAME} style={{height: '3.5rem'}}/>
                                }
                            </div>
                            <div className="footer-about-content">
                                <div className="social-icon">
                                    <ul className="d-flex justify-content-around w-75">
                                        <li>
                                            <a href="#" target="_blank"><i className="fab fa-facebook-f"></i> </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank"><i className="fab fa-twitter"></i> </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank"><i className="fab fa-linkedin-in"></i></a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank"><i className="fab fa-instagram"></i></a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank"><i className="fab fa-dribbble"></i> </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-widget footer-menu">
                            <h2 className="footer-title">For Patients</h2>
                            <ul>
                                <li><a href="#"><i className="fas fa-angle-double-right"></i> Search for Doctors</a></li>
                                <li><a href="#"><i className="fas fa-angle-double-right"></i> Login</a></li>
                                <li><a href="#"><i className="fas fa-angle-double-right"></i> Register</a></li>
                                <li><a href="#"><i className="fas fa-angle-double-right"></i> Booking</a></li>
                                <li><a href="#"><i className="fas fa-angle-double-right"></i> Patient Dashboard</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-widget footer-menu">
                            <h2 className="footer-title">For Doctors</h2>
                            <ul>
                                <li><a href="#l"><i className="fas fa-angle-double-right"></i> Appointments</a></li>
                                <li><a href="#"><i className="fas fa-angle-double-right"></i> Login</a></li>
                                <li><a href="#"><i className="fas fa-angle-double-right"></i> Register</a></li>
                                <li><a href="#"><i className="fas fa-angle-double-right"></i> Doctor Dashboard</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-widget footer-contact">
                            <h2 className="footer-title">Contact Us</h2>
                            <div className="footer-contact-info">
                              <div className="footer-address">
                                <span><i className="fas fa-map-marker-alt"></i></span>
                                {/* <p> 3556  Beech Street, San Francisco,<br/> California, CA 94108 </p> */}
                                <p>{compInfo.ADDRESS}</p>
                              </div>
                              <p><i className="fas fa-phone-alt"></i> +91 {compInfo.CONTACT1}</p>
                              {/* <p className="mb-0 text-decoration-line-through"><i className="fas fa-envelope"></i> mailExample@gmail.com</p> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="copyright-menu d-flex flex-column flex-md-row w-100 justify-content-between align-items-center">
                        <ul className="policy-menu mb-3">
                            <li><a href="#">Terms and Conditions</a></li>
                            <li><a href="#">Policy</a></li>
                        </ul>
                        {dropdownActive && <select className="form-select" value={userInfo.UserType} onChange={(e) => {userInfoAction({UserType: e.target.value}); setDropdownActive(false)}} style={{maxWidth: 'fit-content'}}>
                          <option value="PATIENT">Patient</option>
                          <option value="DOCTOR">Doctor</option>
                          <option value="POLYCLINIC">Polyclinic</option>
                          <option value="COLLECTOR">Collector</option>
                          <option value="PROVIDER">Provider</option>
                        </select>}
                        <p className="text-white my-2"><span onClick={() => setDropdownActive(!dropdownActive)}>Powered by </span> &nbsp;<img className="logo" style={{maxHeight: '2.5rem'}} src="img/logo/612.jpeg" alt="Gbooks" /></p>
                    </div>
                </div>
            </div>
        </div>
      </footer>
      <ModalComponent isActive={bookingModalOpen} heading="Book Appointment" child={<ConnectedBookingModal setRefNo={setRefNo} setRefActive={setRefActive}/>} handleClose={bookingModalAction} />
      <ModalComponent isActive={refActive} heading="Thank You for Booking." child={showReference()} handleClose={setRefActive} />
    </>
  )
}

const mapStateToProps = (state) => {
  return { compCode: state.compCode, compInfo: state.compInfo, bookingModalOpen: state.bookingModalOpen, userInfo: state.userInfo, isMobile: state.isMobile };
}

export default connect(mapStateToProps, {bookingModalAction, userInfoAction})(Footer);


const BookingModal = ({ compCode, bookingInfo, setRefNo, setRefActive, bookingModalAction,  modalAction, loginModalAction, bookingInfoAction, isLoggedIn, userInfo, userInfoAction, loaderAction, compInfo }) => {

  const history = useHistory();
  const [dateTabsList, setDateTabsList] = useState({loading: true, data: [], err: {status: false, msg: ''}});
  const [dateSlotsList, setDateSlotsList] = useState({loading: true, data: [], err: {status: false, msg: ''}});
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [shift, setShift] = useState({ allShifts: [{name: '', duration: '', docInTime: '', docOutTime: ''}], activeShift: ''});
  const [appnPreviewActive, setAppnPreviewActive] = useState(false); 
  const [bookingData, setBookingData] = useState({
    AppointDate: '',
    AppTime: '',
    TimeSlotId: null,
  })

  const docAvailable = useState(true)[0];
  const sliderRef = useRef();
  
  useEffect(() => {
    const controller4 = new AbortController();
    getDateTabsList(controller4.signal);
    return () => {    
      controller4.abort();                                                             
      setSelectedSlot(null);                                // Reset selected date slot whenever selected doctor changes.                
      setDateSlotsList(preValue => {
        return {...preValue, loading: true};                // set dateSlotsList to loading phase.
      });                                                   // Or can also remove Dep. array to reset it on every render.
    }                                                                              
  },[bookingInfo.UnderDoctId, userInfo.selectedCompany.EncCompanyId])                                             
  
  const handleBookingFormSubmit = async (e) => {
    e.preventDefault();
    if (!bookingInfo.TimeSlotId) {
      alert('Please select a time slot first.');
      return;
    }
    if (isLoggedIn) {
      bookingModalAction(false);
      if (!userInfo.selectedMember.MemberId) {
        const newbookingData = { 
          ...bookingInfo,
          Salutation: userInfo.Salutation,
          Name: userInfo.Name,
          EncCompanyId: userInfo.selectedCompany.EncCompanyId ,
          PartyCode: userInfo.selectedCompany.CompUserPartyCode,
          MPartyCode: userInfo.selectedCompany.CompUserMPartyCode,
          RegMob1: userInfo.RegMob1,
          Gender: userInfo.Gender ,
          GenderDesc: userInfo.GenderDesc ,
          Address: userInfo.Address ,
          Age: userInfo.Age,
          AgeMonth: userInfo.AgeMonth,
          AgeDay: userInfo.AgeDay,
          State: userInfo.State ,
          City: userInfo.City,
          Pin: userInfo.Pin,
          Address2: userInfo.Address2,
          AnniversaryDate: userInfo.AnniversaryDate,
          Aadhaar: userInfo.Aadhaar,
          UserId: userInfo.UserId,
          UHID: userInfo.UHID,
          MemberId: userInfo.MemberId,
          Country: userInfo.Country,
        }
        console.log('user booking');
        makeBookingRequest(newbookingData);
      } else {
        const newbookingData = { 
          ...bookingInfo,
          Salutation: userInfo.selectedMember.Salutation,
          Name: userInfo.selectedMember.MemberName,
          EncCompanyId: userInfo.selectedCompany.EncCompanyId ,
          PartyCode: userInfo.selectedMember.CompUserPartyCode,
          MPartyCode: userInfo.selectedMember.CompUserMPartyCode,
          RegMob1: userInfo.selectedMember.Mobile,
          Gender: userInfo.selectedMember.Gender ,
          GenderDesc: userInfo.selectedMember.GenderDesc ,
          Address: userInfo.selectedMember.Address ,
          Age: userInfo.selectedMember.Age,
          AgeMonth: userInfo.selectedMember.AgeMonth,
          AgeDay: userInfo.selectedMember.AgeDay,
          State: userInfo.selectedMember.State ,
          City: userInfo.selectedMember.City,
          Pin: userInfo.selectedMember.Pin,
          Address2: userInfo.selectedMember.Landmark,
          AnniversaryDate: userInfo.selectedMember.AnniversaryDate,
          Aadhaar: userInfo.selectedMember.Aadhaar ,
          UserId: userInfo.UserId,
          UHID: userInfo.selectedMember.UHID,
          MemberId: userInfo.selectedMember.MemberId,
          Country: userInfo.selectedMember.Country,
        }
        console.log('member booking');
        makeBookingRequest(newbookingData);
      }
      setTimeout(() => { history.push('/dashboard') }, 1000);
    } else {
      userInfoAction(bookingData);
      // bookingModalAction(false);
      loginModalAction(true, 'PATIENT');
    }
  }

  async function getDateTabsList(signal) {
    const res = await getFrom(`${process.env.REACT_APP_BASE_URL}/api/AppSchedule?CID=${userInfo.selectedCompany.EncCompanyId ? userInfo.selectedCompany.EncCompanyId : compCode }&DID=${!bookingInfo.UnderDoctId ? '' : bookingInfo.UnderDoctId}`, {}, setDateTabsList, signal);
    if (res) {
      setTimeout(() => {
        setDateTabsList(res);
        if (bookingInfo.UnderDoctId !== null && bookingInfo.UnderDoctId !== '') {
          getDateSlotsList(bookingInfo.UnderDoctId, res.data[0]?.SDateStr || '');          // Pass first received tab Date (SDateStr) only if it't exist to make a new request for app slots.
        }  
      },500)                                                                             // At initial render we don't have any dateTabsList and sDateStr hence replace it with empty strings.
    }
  }       

  async function getDateSlotsList(docId, sDate) {
    if (docId !== null) {         
      const res = await getFrom(`${process.env.REACT_APP_BASE_URL}/api/AppSchedule?CID=${userInfo.selectedCompany.EncCompanyId ? userInfo.selectedCompany.EncCompanyId : compCode }&DID=${docId}&FDate=${sDate}&TDate=${sDate}`, {}, setDateSlotsList);
      if (res) {
        setTimeout(() => {
          setDateSlotsList(res);

          if (res.data.length > 0) {          
            const shiftFound = res.data.map(i => ({ name: i.ShiftType, duration: i.ScheduleTimeStr, docInTime: i.DoctorInTimeStr, docOutTime: i.DoctorOutTimeStr }));
            const uniqueShifts = [...new Map(shiftFound.map(item => [item['name'], item])).values()];
            setShift({ allShifts: uniqueShifts, activeShift: uniqueShifts[0].name });
          } else {                                                                    
            setShift({ allShifts: [{name: '', duration: '', docInTime: '', docOutTime: ''}], activeShift: ''});         // reset the shifts if no slots received.
          }
        }, 1000);
      }
    } 
  }

  const selectSlot = (AutoId, SDateStr, SInTimeStr, EncCompanyId) => {
    setBookingData(preValue => {
      return {...preValue, AppointDate: SDateStr, AppTime: SInTimeStr, TimeSlotId: AutoId }
    })
    bookingInfoAction({ AppointDate: SDateStr, AppTime: SInTimeStr, TimeSlotId: AutoId, companyId: EncCompanyId });
    setSelectedSlot(AutoId);
  }

  const makeBookingRequest = async (params) => {
    console.log(params);
    if (!params.UserId) return alert('Something went wrong, try again later. No user Id received: F');
    loaderAction(true);
    const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/Appointment`, params);
    loaderAction(false);
    if (res.status === 200) {
      setRefNo(res.data); 
      setRefActive(true);
    } else {
      alert('Something went wrong, try again later.');
    }
  }

  const renderDateSlotsList = (shift, data) => {
    return (
      data.data.map(item => {
        if (item.ShiftType === shift) {
          return (
            <span key={item.AutoId} className={`dateSlot btn my-1 btn-secondary ${selectedSlot === item.AutoId ? 'selectedSlotDate' : ''}`} onClick={() => selectSlot(item.AutoId, item.SDateStr, item.SInTimeStr, item.EncCompanyId)}>
              {item.TimeStr}
            </span>
          )
        }
      })
    )
  }

  const renderShift = (data) => {
    if (data.loading) {
      return <div className='w-100'><Skeleton count={7}/></div>;
    } else if (data.err.status) {
      return <div className='text-center my-5'><h2 className="text-danger mark">An error occured, please try again later. Error code: <span className='text-dark'>{data.err.msg}</span></h2></div>;
    } else if (data.data.length === 0) {
      return <span className='btn m-1 btn-secondary'>No Slot Found</span>;
    } else {
      return (
        <>
          <ul className="nav nav-tabs icon-btn-box" role="tablist">
            {shift.allShifts[0].name && shift.allShifts.map(currShift => {
              return (  
                <li className="nav-item" role="presentation" key={currShift.name}>
                  <button type="button" id="shift-1" className={`btn-item ${shift.activeShift === currShift.name && 'active'}`} onClick={() => setShift(pre => ({...pre, activeShift: currShift.name}))}><i className="icofont-sun" style={{color: shift.activeShift === currShift.name && '#e58b06'}}></i> {currShift.name} <span className="p d-block nTiming" style={{lineHeight: '1.25em', fontSize: 'clamp(0.4em, 1.9vw, 0.7em)', fontWeight: 'normal'}}>{currShift.duration}</span></button>
                </li>
              )
            })}
          </ul>
          <div className="tab-content slot-wrapper">
            {shift.allShifts.map(currShift => {
              return (
                <div key={currShift.name} id="shift-pane-1" className={`tab-pane fade ${shift.activeShift === currShift.name && 'show active'}`} role="tabpanel" aria-labelledby="shift-1">
                  <div className="col-sm-12 d-flex justify-content-between doc-status gap-2 px-0 pe-none">
                    <h4><span>In Time - {currShift.docInTime}</span><i className='bx bxs-camera-home' title="Take Photo" style={{cursor: 'pointer', color: 'orangered', paddingTop: '1px'}}></i><span>Out Time - {currShift.docOutTime}</span></h4>
                    <div className='check-status'>
                      <label className="toggle-switch">
                        <input type="checkbox" readOnly checked={docAvailable}/>
                        <div className="toggle-switch-background">
                            <div className="toggle-switch-handle"></div>
                        </div>
                        <span className="check"></span>
                      </label>
                      <h5 className={`mb-0 ${docAvailable ? 'text-info' : 'opacity-25'}`}>{docAvailable ? 'Available' : 'Unavailable'}</h5>
                    </div>
                  </div>
                  <div className="col-12 d-flex justify-content-between align-items-center flex-wrap pt-2 position-relative gap-1" style={{maxHeight: "12.2rem", minHeight: '5rem', overflowY: "auto", padding: '3px', marginTop: '0.5em'}}>
                    {renderDateSlotsList(currShift.name, data)}   
                  </div>
                </div>
              )
            })}
          </div> 
        </>
      )
    }
  }

  const tabList = dateTabsList.data.map((item, index) => {
      return (
        <button onClick={() => getDateSlotsList(bookingInfo.UnderDoctId, item.SDateStr)} key={item.SDateStr} type="button" id='tabButtons-1' className={`nav-item nav-link d-flex justify-content-center align-items-center ${index === 0 ? 'active' : ''} slotDate`} data-bs-toggle="tab" data-bs-target='#tabButtons-pane-1' role="tab" aria-controls='tabButtons-pane-1' aria-selected="true">
          <h5 style={{fontSize: 'clamp(1em, 3.8vw, 1.4em)', margin: '0 1px 0 0', fontWeight: 'bold'}}>{item.Day}</h5>
            <div>
              <span className="p d-block mb-0 nMonth" style={{lineHeight: '1.25em', fontSize: 'clamp(0.4em, 1.9vw, 0.7em)'}}>{item.Month}</span>
              <span className="p d-block mb-0 text-capitalize nDay" style={{lineHeight: '1.2em', fontSize: 'clamp(0.4em, 1.9vw, 0.7em)'}}>{item.DName.substring(0, 3)}</span>
            </div>
        </button>
      )
  })  

  const renderTabCarousel = (data) => {
    if (data.loading) {
      return <div className='w-100 py-3'><Skeleton count={3}/></div>;
    } else if (data.err.status) {
      return <div className='text-center my-5'><h2 className="text-danger mark">An error occured, please try again later. Error code: <span className='text-dark'>{data.err.msg}</span></h2></div>;
    } else if (data.data.length === 0) {
        return <h4 className="card-title my-2">No Appoinment Dates found</h4>;
    } else {
      return <div className="nav nav-tabs py-2 px-2 button-carousel d-block" role="tablist" style={{borderBottom: 'none'}}><ButtonSlider dataList={tabList} responsive={[{ breakpoint: 880, settings: { slidesToShow: 5} }]}/></div>;
    }
  }

  const handleAppnPreview = () => {
    if (!bookingInfo.TimeSlotId) return alert('Please select a time slot first.');
    if (!isLoggedIn) {
      userInfoAction(bookingData);
      // bookingModalAction(false);
      loginModalAction(true, 'PATIENT');
    }
    setAppnPreviewActive(true);
  }

  const [companyTabList, setCompanyTabList] = useState({loading: true, data: {CompanyMasterList: []}, err: {status: false, msg: ''}});
  const [activeCompany, setActiveCompany] = useState(userInfo.selectedCompany);

  const getCompanyTabList = async (partyId, userId) => {
    const res = await getFrom(`${process.env.REACT_APP_BASE_URL}/api/search/Get?PID=${partyId}&UID=${userId}`, {}, setCompanyTabList);
    if (res) {
      setCompanyTabList(res); 
    } else {
      console.log('No data received');
    }
  }
  
  useEffect(() => {
    getCompanyTabList(bookingInfo.Doctor?.PartyId, userInfo.UserId);
  }, [bookingInfo.Doctor?.PartyId, userInfo.UserId])

  // useEffect(() => {
  //   console.log(companyTabList);
  //   if (companyTabList.data.CompanyMasterList.length === 0) return;
  //   userInfoAction({ selectedCompany: companyTabList.data.CompanyMasterList[0] });
  // }, [companyTabList.data])

  // useEffect(() => {
  //   setActiveCompany(userInfo.selectedCompany);
  // },[userInfo.selectedCompany])
  
  // useEffect(() => {
  //   window.renderCompanySlider();
  // },[companyTabList.data])

  const handleCompanySelect = (item) => {
    setActiveCompany(item);                                                                                                              // resetting Department to get All specialists on specialists page when user revisits that page.         
    userInfoAction({selectedCompany: item, Department: {dName: 'All', SubCode: 0} });      // This will avoid the mismatch of company id and spicialists id.
  }

  const renderCompList = (data) => {
    if (data.loading) {
      return <div className='w-100'><Skeleton count={3}/></div>;
    } else if (data.err.status) {
      return <div className='text-center my-5'><h3 className="text-danger mark">An error occured, please try again later. Error code: <span className='text-dark'>{data.err.msg}</span></h3></div>;
    } else if (data.data.CompanyMasterList.length === 0) {
      return <div className='text-center'><h2 className="text-info mark">No Clinics found</h2></div>;
    } else {

      const cards = data.data.CompanyMasterList.map(item => (
        <div key={item.EncCompanyId}>
          <div className={`companyTabCard d-flex cursor-pointer position-relative  ${item.EncCompanyId === activeCompany.EncCompanyId ? 'active' : ''}`} onClick={() => handleCompanySelect(item)}>
            <img src={`https://erp.gsterpsoft.com/Content/CompanyLogo/${item.LogoUrl}`} className="img-fluid logo" style={{maxHeight: '1.9em', margin: '0 0.5em 0.4em 0'}} alt={item.COMPNAME}/>
            <div className=''>
              <h5 className="mb-0">{item.COMPNAME}</h5>
              <h6>{item.ADDRESS}</h6>
            </div>  
            {/* <span className='d-flex flex-column justify-content-between h-100'><Link to={'/bedStatus'}><i className='bx bxs-bed' ></i></Link><Link to={`/patientQueue`}><i className='bx bxs-user-plus'></i></Link></span>                      */}
          </div>
        </div>
      ))
      return <CompanySlider dataList={cards} myRef={sliderRef}/>
    }
  }

  const RenderBookingModal = () => {

    return (
      <section className="position-relative" id="booking-modal" style={{display: appnPreviewActive && 'none'}}>
        <div className="row">
          <div className="col-12">
            <div className="card-header px-0 py-0 d-flex gap-2 border-0">
              <h4 className="card-title selected_doc">{bookingInfo.Doctor.Name} </h4>{bookingInfo.Doctor.SpecialistDesc !== '' && <span style={{fontSize: '0.7em', lineHeight: '2em'}}>({bookingInfo.Doctor.SpecialistDesc})</span>}
            </div>
              {renderCompList(companyTabList)}
          </div>
          <div className="col-12">
            <div className="card card-table mb-0">
              <div className="card-body">
                <form className="bg-white rounded tabs-carousel" onSubmit={handleBookingFormSubmit}>
                  <div className="row">
                    <div className="col-12 pb-2">

                      <nav className="button-carousel" style={{fontSize: '0.9em', padding: '0.25em 0.4em 0'}}>
                          {renderTabCarousel(dateTabsList)}
                      </nav>                                                                        

                      <div className="tab-content pt-1">
                        <div id='tabButtons-pane-1' className='tab-pane active show'  role="tabpanel" aria-labelledby="tabButtons-1">                          
                          {renderShift(dateSlotsList)}
                        </div>
                      </div> 
                    </div> 
                    <div className="col-12" style={{paddingTop: '1em', borderTop: '1px solid #0de0fe'}}>
                      <button type="button" onClick={() => handleAppnPreview()} className="btn btn-primary px-3 d-block ms-auto btnSave fw-bold" tabIndex={1} style={{ minWidth: "8em" }} > NEXT </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

  const handleMemberSelection = async (item, companyCode) => {
    if (!item) return;
    try {      
      loaderAction(true);
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/member/GetMemberIsDefault?MemberId=${item.MemberId}&CID=${companyCode}`, {});
      loaderAction(false);
      if (!res.data.MemberId) return alert('Something went wrong. Please try again.');
      userInfoAction({selectedMember: res.data});
    } catch (error) {
      alert('Something went wrong please try after some time.');
    }
  }

  const renderCurrentMember = () => {
    if (Object.keys(userInfo.selectedMember).length === 0) {
      return (
        <>
          <div className='dashboard-card__img-box'>
            <img src="/img/user_unknown.png" alt="User" />
            <div className="img">
              <Link to={'#'} title={userInfo.Name}>{userInfo.Name}</Link>
              {userInfo.UHID && <span>UHID : {userInfo.UHID}</span>}
            </div>
          </div>
          <div className="dashboard-card__content-box w-100">
            <p><span><i className='bx bxs-phone-call'></i> Phone : </span><span> +91 {userInfo.RegMob1}</span></p>
            <p><span><i className="bx bxs-hourglass-bottom"></i> Age : </span><span> {userInfo.Age} Years</span></p>
            <p><span><i className='bx bx-male-female'></i> Gender : </span> <span>{userInfo.GenderDesc}</span></p>
            <p className='mb-0'><span><i className='bx bxs-map'></i> Address : </span> <span>{userInfo.Address}</span></p>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className='dashboard-card__img-box'>
            <img src="/img/user_unknown.png" alt="User" />
            <div className="img">
              <Link to={'#'} title={userInfo.selectedMember.MemberName}>{userInfo.selectedMember.MemberName}</Link>
              {userInfo.selectedMember.UHID && <span>UHID : {userInfo.selectedMember.UHID}</span>}
            </div>
          </div>
          <div className="dashboard-card__content-box w-100">
            <p><span><i className='bx bxs-phone-call'></i> Phone : </span><span> +91 {userInfo.selectedMember.Mobile}</span></p>
            <p><span><i className="bx bxs-hourglass-bottom"></i> Age : </span><span> {userInfo.selectedMember.Age} Years</span></p>
            <p><span><i className='bx bx-male-female'></i> Gender : </span> <span>{userInfo.selectedMember.GenderDesc}</span></p>
            <p className='mb-0'><span><i className='bx bxs-map'></i> Address : </span> <span>{userInfo.selectedMember.Address}</span></p>
          </div>
        </>
      )
    }
  }

  const renderAppnPreview = () => {
    return (
      <div className="card appn-preview mb-0" style={{display: !appnPreviewActive && 'none'}}>
        { isLoggedIn && 
          <>
            <h4 className="card-title mb-2"><i className="fas fa-user-check ps-1 pe-2"></i>Member's Information</h4>
            <div className="card-body" style={{padding: '2px'}}>
              <div style={{fontSize: '0.73em', marginBottom: '1.3em'}}>
                <div className='dashboard-card dashboard-card-user_card'>
                  {renderCurrentMember()}
                  <div className="member-box">
                    <h4 className="card-title mb-2">Family Members</h4>
                    <ul className="list-unstyled mb-2" style={{overflow: 'auto', maxHeight: '12.7rem'}}>
                      <li style={{ background: '#e0feff', paddingRight: '2.9vw' }}>
                        <span>Member Name</span>
                        <span>Date of Birth</span>
                        <span>Gender</span>
                        <span>Action</span>
                      </li>
                      {userInfo.MembersList.AccPartyMemberMasterList?.map(item => (
                        <li key={item.MemberId}>
                          <span title={`Select ${item.MemberName} for Appointment`} onClick={() => handleMemberSelection(item, userInfo.selectedCompany.EncCompanyId)}><i className={`bx bx-${userInfo.selectedMember?.MemberId === item.MemberId ? 'check-' : ''}circle`} ></i> {item.MemberName}</span>
                          <span>{new Date(item.DOB).toLocaleDateString('es-CL')}</span>
                          <span>{item.GenderDesc}</span>
                          <span><i onClick={() => modalAction('MEMBER_MODAL', true, {editId: item.MemberId})} className='bx bx-edit me-sm-3' title="Edit"></i> <i className='bx bx-trash' title="Delete"></i></span>
                        </li>
                      ))}
                    </ul>
                    <div className="dashboard-card__btn-box">
                      <button onClick={() => modalAction('MEMBER_MODAL', true)} className='dashboard-card__btn-box-item' style={{'--clr': '#149A8D', '--bg': '#48fffc3b', '--bClr': '#149a8d57'}}>Add New Member</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
        <h4 className="card-title mb-2"><i className="fas fa-stethoscope px-1"></i> Doctor Information</h4>
        <div className="card-body" style={{fontSize: '0.73em', padding: '2px'}}>
          <div className='dashboard-card'>
            <div className='dashboard-card__img-box'>
              <img src="/img/user_unknown.png" alt="User" />
              <div className="img">
                <Link to={`/doctorProfile/${bookingInfo.Doctor.Name}`} title={bookingInfo.Doctor.Name}>{bookingInfo.Doctor.Name}</Link>  
                <span>{bookingInfo.Doctor.SpecialistDesc}</span>
                <span>{bookingInfo.Doctor.Qualification}</span>                                 
                <span>{bookingInfo.Doctor.Mobile}</span>                                         
              </div>
            </div>
            <div className="dashboard-card__content-box">
              <p><span>Clinic Name :</span> <span style={{color: '#3F51B5'}}>{userInfo.selectedCompany.COMPNAME}</span></p>
              <p><span>App Date :</span> <span><i className='bx bxs-calendar'></i> {bookingInfo.AppointDate}</span><span><i className='bx bx-time-five'></i> {bookingInfo.AppTime}</span></p>
              <p><span>Service :</span> <span>OPD</span></p>
            </div>  
          </div>
        </div>
        <div className="mt-2 d-flex justify-content-between">
          <button type="button" onClick={() => {setAppnPreviewActive(false)}} className="btn btn-primary d-block btnSave fw-bold" tabIndex={1} style={{ width: "10rem", borderRadius: '0' }} > PREVIOUS </button>
          <button type="button" onClick={(e) => {handleBookingFormSubmit(e)}} className="btn btn-primary d-block btnSave fw-bold" tabIndex={1} style={{ width: "10rem", borderRadius: '0' }} > CONFIRM </button>
        </div>
      </div>
    )
  }
    
  return (
    <>
      {/* {appnPreviewActive ? renderAppnPreview() : RenderBookingModal()} */}
      {renderAppnPreview()}
      {RenderBookingModal()}
    </>
  )
}

const mapStateToPropsTwo = (state) => {
  return { bookingModalOpen: state.bookingModalOpen, compInfo: state.compInfo, loginModalOpen: state.loginModalOpen, compCode: state.compCode, isLoggedIn: state.isLoggedIn, userInfo: state.userInfo, bookingInfo: state.bookingInfo };
}

export const ConnectedBookingModal = connect(mapStateToPropsTwo, {bookingModalAction, modalAction, loginModalAction, loginStatusAction, userInfoAction, loaderAction, bookingInfoAction })(BookingModal);
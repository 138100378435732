export const compCodeAction = (code) => {
  return {
    type: "PARTY_CODE",
    value: code
  }
}

export const compInfoAction = (item) => {
  return {
    type: 'COMPANY_INFO',
    payload: item
  }
}

export const loginModalAction = (status, mode) => {
  return {
    type: "LOGIN_MODAL",
    payload: {
      status: status,
      mode: mode
    }
  }
}

export const modalAction = (modalName, status, modalData='') => {
  return {
    type: 'MODAL',
    payload: {
      name: modalName,
      status: status,
      data: modalData
    }
  }
}

export const loginStatusAction = (status) => {
  return {
    type: "LOGIN",
    value: status
  }
}

export const bookingModalAction = (modalStatus) => {
  return {
    type: "BOOKING_MODAL",
    value: modalStatus
  }
}

export const loaderAction = (status) => {
  return {
    type: 'LOADING',
    value: status
  }
}

export const userInfoAction = (item) => {
  return {
    type: 'USER_INFO',
    payload: item
  }
}

export const bookingInfoAction = (item) => {
  return {
    type: 'BOOKING_DATA',
    payload: item
  }
}

export const siteDataAction = (data) => {
  return {
    type: 'SITE_DATA',
    payload: data
  }
}

export const cartAction = (task, item) => {
  return {
    type: task,
    payload: item
  }
}

export const ordersAction = (task, item) => {
  return {
    type: task,
    payload: item
  }
}

export const toastAction = (status, item) => {
  return {
    type: 'SHOW_TOAST',
    payload: {
      status: status,
      item: item
    }
  }
}

export const schedulerAction = (item) => {
  return {
    type: 'SCHEDULE_DATA',
    payload: item
  }
}

export const isMobileAction = (status) => {
  return {
    type: 'IS_MOBILE',
    value: status
  }
}

export const  addToCartAction = () => {}
export const addToWishlistAction = () => {}
export const quickviewItemAction = () => {}
export const removeFromWishlistAction = () => {}
export const removeFromCartAction = () => {}

import { Link, useHistory } from 'react-router-dom';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { loginModalAction, loginStatusAction, userInfoAction, compInfoAction, compCodeAction, loaderAction, siteDataAction, modalAction } from './../actions';
import { connect } from 'react-redux';
import { useFetch, useDocumentTitle, ModalComponent, handleNumberInputs, logOut, customTabsButtons, createDate, getDuration, MODULES, encrypt, decrypt } from './utilities';
import axios from 'axios';
// import qs from 'query-string';
import Menu from './menu';
import DatePicker from "react-datepicker";


const Header = ({ loginModalOpen, siteData, loginModalAction, isLoggedIn, loginStatusAction, userInfo, userInfoAction, cart, compCode, compInfo, compInfoAction, compCodeAction, loaderAction }) => {

  const history = useHistory();

  useEffect(() => {
      return history.listen((location) => {                         // Listen for changes in history object or Url changes to toggle active menu link.
          setActiveLink(location.pathname);
          setMenuOpen(false);
      })
  },[history]) 

  useEffect(() => {
    if (window.location.hash === '#/specialists') {               // set activeLink '/' on page load to highlight the home page link.
      setActiveLink('/specialists');
    } else if (window.location.hash === '#/pharmacy') {
      setActiveLink('/pharmacy');
    } else if (window.location.hash === '#/labTests') {
      setActiveLink('/labTests');
    }     
  }, [])

  // const queryString = qs.parse(window.location.search, { ignoreQueryPrefix: true, decode: false });

  // useEffect(() => {
  //   if (queryString.CID && queryString?.CID !== compCode) {                     // immediate calling api's that uses CID parameter must be filtered before calling
  //     compCodeAction(queryString.CID);                                          // otherwise race condition will take place. not suitable solution is found till now.
  //     getCompanyDetails(queryString.CID);
  //   } else {
  //     getCompanyDetails(compCode);
  //   }
  // }, [queryString.CID, compCode, compCodeAction])

  useEffect(() => {
    getCompanyDetails(compCode);
  }, [compCode])

// ----------------------- Auto Login Starts --------------------------------------------------------------------------------------------------------------------------

  // function AutoLogin(companyCode) {
  //   const savedLoginData = decrypt(localStorage.getItem('userLoginData'));
  //   if (companyCode !== '') {
  //     if (savedLoginData) compareCompCodes(companyCode, savedLoginData); 
  //   } else {
  //     console.log('No root compcode found');
  //     saveAndLoginUser(savedLoginData);
  //   }
  // }
  // function compareCompCodes(currCompCode, savedLoginData) {               // defining it outside the useEffect without usecallback makes it not trigger on compcode changes. leaving user logged out. 
  //   if (currCompCode === savedLoginData.compCode) {
  //     console.log(currCompCode, savedLoginData.compCode);
  //     saveAndLoginUser(savedLoginData);
  //     return;
  //   }
  //   console.log('Company code Mismatch.');
  //   loginStatusAction(false);
  // }

  // const saveAndLoginUser = (savedData) => {
  //   if (savedData && savedData.phone) {
  //     console.log('Logging you in! Please wait.');        
  //     makeLoginRequest({ phone: savedData.phone, password: savedData.password, companyCode: savedData.compCode });
  //   } 
  // }

  // const makeLoginRequest = async (params) => {
  //   loaderAction(true);
  //   const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/UserAuth?UN=${params.phone}&UP=${params.password}&CID=${params.companyCode}`);
  //   loaderAction(false);
  //   if (res.data.UserId === 0) {
  //     console.log('The username or password is incorrect.');
  //   } else {
  //     let userLoginData = {
  //                           Name: res.data.UserFullName,
  //                           RegMob1: params.phone,
  //                           UserId: res.data.UserId,
  //                           UserType: res.data.UserType,
  //                           PartyCode: res.data.PartyCode,
  //                           EncCompanyId: params.companyCode,
  //                           Age: res.data.Age,
  //                           AgeDay: res.data.AgeDay,
  //                           AgeMonth: res.data.AgeMonth,
  //                           Gender: res.data.Gender,
  //                           GenderDesc: res.data.GenderDesc,
  //                           MPartyCode: res.data.MPartyCode,
  //                           Address: res.data.Address,
  //                           Qualification: res.data.Qualification,
  //                           SpecialistDesc: res.data.SpecialistDesc,
  //                           RegNo: res.data.RegNo ? res.data.RegNo : '',
  //                           State: res.data.State,                          
  //                           City: res.data.City,
  //                           Pin: res.data.Pin,
  //                           Address2: res.data.Address2,
  //                           UHID: res.data.UHID,
  //                           MemberId: res.data.MemberId,
  //                           PartyId: res.data.PartyId,
  //                           Salutation: res.data.Salutation,
 
  //                           DOB: res.data.DOB,
  //                           AnniversaryDate: res.data.AnniversaryDate,
  //                           Aadhaar: res.data.Aadhaar,
  //                           IsDOBCalculated: res.data.IsDOBCalculated,
 
  //                           compName: res.data.compName ? res.data.compName: '',
  //                           compAddress: res.data.compAddress ? res.data.compAddress: '',
  //                           compState: res.data.compState ? res.data.compState: '',
  //                           compPin: res.data.compPin ? res.data.compPin: '',
  //                           compPhone1: res.data.compPhone1 ? res.data.compPhone1: '',
  //                           compPhone2: res.data.compPhone2 ? res.data.compPhone2: '',
  //                           compMail: res.data.compMail ? res.data.compMail: '',
  //                        };
  //      localStorage.setItem("userLoginData", encrypt({ phone: params.phone, password: res.data.UserPassword, compCode: params.companyCode }));
  //      userInfoAction(userLoginData);
 
  //      const newbookingData = { 
  //        ...bookingInfo,
  //        Salutation: res.data.Salutation,
  //        Name: res.data.Name,
  //        EncCompanyId: userInfo.selectedCompany.EncCompanyId,
  //        PartyCode: userInfo.selectedCompany.CompUserPartyCode,
  //        MPartyCode: userInfo.selectedCompany.CompUserMPartyCode,
  //        RegMob1: res.data.RegMob1,
  //        Gender: res.data.Gender ,
  //        GenderDesc: res.data.GenderDesc ,
  //        Address: res.data.Address ,
  //        Age: res.data.Age,
  //        AgeMonth: res.data.AgeMonth,
  //        AgeDay: res.data.AgeDay,
  //        State: res.data.State ,
  //        City: res.data.City,
  //        Pin: res.data.Pin,
  //        Address2: res.data.Address2,
  //        AnniversaryDate: res.data.AnniversaryDate,
  //        Aadhaar: res.data.Aadhaar ,
  //        UserId: res.data.UserId,
  //        UHID: res.data.UHID,
  //        MemberId: res.data.MemberId,
  //        Country: res.data.Country,
  //      }
  //      handleRedirect(res.data.UserType, newbookingData);
 
  //      loginModalAction(false, 'PATIENT');
  //      loginStatusAction(true);
  //    }
  // }

  // const handleRedirect = (userType, bookingData) => {
  //   switch (userType) {
  //     case 'PATIENT':
  //       if (!bookingData.TimeSlotId) {
  //         // history.push('/');
  //       } else {
  //         // makeBookingRequest(bookingData);        
  //         // setTimeout(() => { history.push('/dashboard') }, 1000);
  //       }
  //       break;
  //     default:
  //       history.push('/dashboard');
  //       break;
  //   }                                                    
  // }

// ----------------------- Auto Login Ends --------------------------------------------------------------------------------------------------------------------------


  useDocumentTitle(compInfo.COMPNAME);
  const [activeLink, setActiveLink] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const totalCartItems = Object.keys(cart).length;          // Add Pharmacy cart and Labtest cart items list to get total no. of cart items.   
  const [searchBoxOpen, setSearchBoxOpen] = useState(false);
  const [mobileSearchBoxOpen, setMobileSearchBoxOpen] = useState(false);
  const searchBoxRef = useRef();
  const mobileSearchBoxRef = useRef();

  // ----------------------------------------SEARCH BAR-------------------------------------------------
  const [searchTerm, setSearchTerm] = useState({query: '', filterTerm: 'All', filterId: 0});               // Avoid using null for filterId.
  const [searchList, setSearchList] = useState([]);
  const [activeListItem, setActiveListItem] = useState(0);

  useEffect(() => {
    const onKeyDown = (e) => {
        if (searchList.length === 0) return;
        if (e.keyCode === 40 && activeListItem + 1 !== searchList.length) {
            setActiveListItem(preValue => preValue + 1);
        } else if (e.keyCode === 38 && activeListItem !== 0) {
            setActiveListItem(preValue => preValue - 1);
        }                                                                          
    }
    document.body.addEventListener('keydown', onKeyDown, { capture: true });                                               
    return () => document.body.removeEventListener('keydown', onKeyDown, { capture: true });                               
  }, [searchList.length, activeListItem])

  const handleSearch = (e) => {
    const { name, value } = e.target;
    setSearchTerm(preValue => {
        return {...preValue, [name]: value};
    })
  }
  
  const handleSearchForm = (e) => {
    e.preventDefault();                                                             // Since searchFunction is already being continuously called using useEffect on line 158 hence we don't need to 
    history.push(`/productPage/${searchList[activeListItem].ItemId}`);              // call it when form is submitted. We just redirect the user to productPage with current active search item's id.
    setSearchList([]);
  }

  const searchItem = useCallback((query, data) => {
    if (data[0] === 'loading') return [];
    let found = data.filter(i => i.Description.toLowerCase().includes(query.toLowerCase()));
    return found;
  }, []);

  const searchFunction = useCallback(() => {
    let filteredByItemId = searchTerm.filterId === 0 ? siteData.itemMasterCollection : siteData.itemMasterCollection.filter(i => i.SubCategoryId === searchTerm.filterId);
    const searchTerms = searchTerm.query.split(' ').filter(i => i !== '');              // Remove spaces from list to prevent searching blank spaces in item name.
    var foundItems = [];                                                                // otherwise will use [''] to search and will return all items with that have blank spaces in their names.
    searchTerms.forEach(query => {
      var searchResults = searchItem(query, filteredByItemId);
      foundItems = foundItems.concat(searchResults);
    })
    var uniqueItems = [...new Map(foundItems.map(item => [item['ItemId'], item])).values()];
    setSearchList(uniqueItems);
    // setSearchList([...new Set(foundItems)]);              // can be deleted safely.
  },[searchTerm, siteData, searchItem])

  useEffect(() => {
        searchFunction();                                            // hence empty input box will return no query to which prevents filter and trigger search.
        setActiveListItem(0);
  },[searchTerm, searchFunction])

  // ----------------------------------------SEARCH BAR-------------------------------------------------

  useEffect(() => {
    const onBodyClick = (event) => {                                                                                        
      if (searchBoxRef.current && searchBoxRef.current.contains(event.target)) return;                                      // Return if click is triggered from serach-box div and it's inner elements.
      setSearchBoxOpen(false);                                                                                              // close search-box only if click is triggered from rest of the elements (outer body).                                                                                                   // no need to use useRef because we wish to remove searchList on any clicks including
      setSearchList([]);
      if (mobileSearchBoxRef.current && mobileSearchBoxRef.current.contains(event.target)) return;                                      // Return if click is triggered from serach-box div and it's inner elements.
      setMobileSearchBoxOpen(false);                                                                                              // close search-box only if click is triggered from rest of the elements (outer body).                                                                                                   // no need to use useRef because we wish to remove searchList on any clicks including
      setSearchList([]);
    }                                                                                                                        
    document.body.addEventListener('click', onBodyClick, { capture: true });                                                // Add eventlistener on component mount.
    return () => document.body.removeEventListener('click', onBodyClick, { capture: true });                                // Remove Eventlistener on component unmount.
  }, [])

  const handleLogOut = () => {
    logOut(loginStatusAction, userInfoAction, compInfo);
    history.push('/');
  }

  const getCompanyDetails = async (companyCode) => {
    try {      
      loaderAction(true);
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/CompMast?CID=${companyCode}`, {});
      if (res.data.COMPNAME && res.data.EncCompanyId) {
        compInfoAction(res.data);
        userInfoAction({selectedCompany: res.data});
      } else {
        alert('Invalid Company Id recieved ! Please enter correct one to continue.');
      }
    } catch (error) {
      alert('Something went wrong please Refresh or try after some time.');
      console.log('No company received. header 129');
    }
    loaderAction(false);
  }

  useEffect(() => {
    const getMembersList = async (companyCode, userId, memberId) => {
      if (!userId) return;
      try {      
        loaderAction(true);
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/member/Get?UserId=${userId}&CID=${companyCode}`, {});
        if (res.data) {
          const parentMember = res.data.AccPartyMemberMasterList.find(i => i.MemberId === memberId);
          if (parentMember) {
            userInfoAction({MembersList: res.data, selectedMember: parentMember});
          } else {
            userInfoAction({MembersList: res.data});
            console.log('No parent member found');
          }
        }
      } catch (error) {
        alert('Something went wrong please Refresh or try after some time.');
      }
      loaderAction(false);
    }
    getMembersList(compCode, userInfo.UserId, userInfo.MemberId);
  },[loaderAction, compCode, userInfo.UserId, userInfo.MemberId, userInfoAction])

  const companyLogo = ['598','608','612','635','636','637','639','620', '659', '680'];
  return (
    <>
      <header className="header">
        <span className="d-none">{activeLink}</span>
        <nav className="navbar navbar-expand-lg header-nav py-2 py-md-0">
        <div className="navbar-header">
          <ul className="nav header-navbar-rht d-flex justify-content-end align-items-center w-100">
            <li className="nav-item">
              <span id="mobile_btn" onClick={() => setMenuOpen(true)}>
                  <span className="bar-icon">
                      <span></span>
                      <span></span>
                      <span></span>
                  </span>
              </span>
            </li>
            <li className="nav-item me-auto pe-0 ps-2">
              <Link to="/" className="navbar-brand logo py-0">
                {
                  companyLogo.includes(compInfo.LogoUrl.split('.')[0]) ? 
                  <img id="header-logo" src={`/img/logo/${compInfo.LogoUrl}`} className="img-fluid logo" alt={compInfo.COMPNAME}/> :                                                                   // 15rem                                                                  
                  <span className='d-flex align-items-center'><img id="header-logo" src={'/img/logo/opd2.png'} className="img-fluid logo rounded-circle" alt={compInfo.COMPNAME}/><span style={{width: 'auto', whiteSpace: 'normal'}} className="d-none d-md-inline text-uppercase text-background"> {compInfo.COMPNAME}</span></span>                                                                                                                                                                                        
                }
              </Link>
            </li>
            <li className="nav-item d-sm-none p-0" style={{display: !isLoggedIn ? '' : 'none'}}>
                <button className="nav-link header-login me-2" onClick={() => loginModalAction(!loginModalOpen.status, 'PATIENT')}>Login</button>
                <button className="nav-link header-login me-2" onClick={() => loginModalAction(!loginModalOpen.status, 'POLYCLINIC')}>Join us</button>
                {/* <button className="nav-link header-login me-2" onClick={() => alert('Search an item')}><i className='bx bx-search' style={{transform: 'scale(1.6)', verticalAlign: 'text-bottom'}}></i></button> */}
            </li>
            
            <li className="nav-item dropdown has-arrow logged-item d-sm-none" style={{display: isLoggedIn ? '' : 'none'}}>
              <Link to="#" className="dropdown-toggle nav-link px-0" data-toggle="dropdown" aria-expanded="false">
                <span className="user-img">
                  <img className="rounded-circle" src="/img/user_unknown.png" width="31" alt="Darren Elder"/>
                </span>
              </Link>
              <div className="dropdown-menu dropdown-menu-right">
                <div className="user-header d-flex align-items-center">
                  <div className="avatar avatar-sm">
                    <img src="/img/user_unknown.png" alt="User" className="avatar-img rounded-circle"/>
                  </div>
                  <div className="user-text">
                    <h6>{userInfo.Name}</h6>
                    {/*<p className="text-muted mb-0">Doctor</p> */}
                  </div>
                </div>
                <Link to='/profile' className="dropdown-item">
                  <i className='bx bx-user-circle h3 mb-0 me-2'></i> Profile
                </Link>
                <Link to='/dashboard' className="dropdown-item">
                  <i className='bx bx-tachometer h3 mb-0 me-2'></i> Dashboard
                </Link>
                {MODULES[compCode]?.includes('PHARMACY') || <Link className="dropdown-item" to="/cartPage"> <i className='bx bx-cart-alt h3 mb-0 me-2'></i> Cart </Link>}
                <span onClick={handleLogOut} className="dropdown-item" to="#">
                  <i className='bx bx-log-out-circle h3 mb-0 me-2'></i> Logout
                </span>
                {/* <a href='#' onClick={getLocation} className="dropdown-item" to="#">
                  <i className='bx bx-log-out-circle h3 mb-0 me-2'></i> Location
                </a> */}
              </div>
            </li>

            <li className="nav-item d-sm-none p-0">
              <span to="#" className='search-open-btn'>
                <form className='search-bar' ref={mobileSearchBoxRef} onSubmit={handleSearchForm}>
                  <div className="input-box">
                    <div className={`${mobileSearchBoxOpen ? 'results-active' : ''} ${searchList.length > 0 ? 'loaded' : ''}`}>
                      <input  id='mobile_search_input' onChange={handleSearch} value={searchTerm.query} name="query" type="text" tabIndex={1} placeholder="Enter your search key ..." />
                      <div className='search-results'>
                        <ul>
                            {searchList.map((i, n) => <li key={i.ItemId} className={`${activeListItem === n && 'active'}`}><Link to={`/productPage/${i.ItemId}`} onClick={() => setSearchList([])}>{i.Description}</Link></li>)}
                        </ul> 
                      </div>
                    </div>
                    <label htmlFor="mobile_search_input">
                        <i className='bx bx-search header-login ms-3' onClick={() => setMobileSearchBoxOpen(!mobileSearchBoxOpen)}></i>
                    </label>
                  </div>
                </form>
              </span>
            </li>

          </ul>

        </div>
        {/* <div className={`main-menu-wrapper ${menuOpen ? 'menu-opened' : ''}`}>
            <div className="menu-header">
                <Link to="/" className="menu-logo">
                    {
                      companyLogo.includes(compInfo.LogoUrl.split('.')[0]) ? 
                      <img src={`/img/logo/${compInfo.LogoUrl}`} className="img-fluid logo" alt={compInfo.COMPNAME}/> :
                      <img src={'/img/logo/opd2.png'} className="img-fluid logo rounded-circle" alt={compInfo.COMPNAME}/>
                    }
                    
                </Link>
                <span id="menu_close" className="menu-close" to="#" onClick={() => setMenuOpen(false)}>
                    <i className="fas fa-times"></i>
                </span>
            </div>
            <ul className="main-nav">
                <li className="" style={{display: isLoggedIn ? '' : 'none'}}>
                  <Link to="#" onClick={() => {setMenuOpen(false);navigateTo()}}>
                    <i className='bx bxs-id-card h3 mb-0 me-2 d-md-none'></i> Dashboard
                  </Link>
                </li>
                <li className='d-md-none'>
                  <Link to='/specialists' onClick={() => {setMenuOpen(false);}}>
                    <i className='bx bxs-contact h3 mb-0 me-2'></i> Specialists
                  </Link>
                </li>
                <li className="d-md-none">
                  <Link to="/cartPage" onClick={() => setMenuOpen(false)}>
                    <i className='bx bx-cart-alt h3 mb-0 me-2'></i> Cart
                  </Link>
                </li>
                <li className="d-md-none">
                  <Link to="/cartPage" onClick={() => setMenuOpen(false)}>
                    <i className='bx bxs-package h3 mb-0 me-2'></i> My Orders
                  </Link>
                </li>
            </ul>
        </div> */}
        <ul className="nav header-navbar-rht">
            {MODULES[compCode]?.includes('DOCTOR') || <li className={`px-0 py-1 tab-btn big-menu ${activeLink === '/specialists' ? 'active': ''}`}>        {/* Toggle activeLink by directly tracking the hash. */}
                <Link className='nav-link' to="/specialists">
                    DOCTOR
                    <hr style={{"margin": "0", "marginTop": "2px", "background": "#0000004f"}} />
                    <span className="d-block mt-1" style={{"fontWeight": "500", "fontSize": "13px"}}>Book Appointments</span>
                </Link>
            </li>}
            {MODULES[compCode]?.includes('PHARMACY') || <li className={`px-0 py-1 tab-btn big-menu ${activeLink === '/pharmacy' ? 'active': ''}`} style={{display: 'inline-flex'}}>
                <Link className='nav-link' to="/pharmacy">
                    PHARMACY
                    <hr style={{"margin": "0", "marginTop": "2px", "background": "#0000004f"}} />
                    <span className="d-block mt-1" style={{"fontWeight": "500", "fontSize": "13px"}}>Medicines & Health Products</span>
                </Link>
            </li>}
            {MODULES[compCode]?.includes('LAB_TEST') || <li className={`px-0 py-1 tab-btn big-menu ${activeLink === '/labTests' ? 'active': ''}`}  style={{display: 'inline-flex'}}>
                <Link className='nav-link' to="/labTests">
                    LAB TESTS
                    <hr style={{"margin": "0", "marginTop": "2px", "background": "#0000004f"}} />
                    <span className="d-block mt-1" style={{"fontWeight": "500", "fontSize": "13px"}}>Health check-ups</span>
                </Link>
            </li>}
            {/*<li className="nav-item contact-item">
                <div className="header-contact-img">
                    <i className="fas fa-headset text-dark"></i>
                </div>
                <div className="header-contact-detail">
                    <p className="contact-header">Contact</p>
                    <p className="contact-info-header"> +1 315 369 5943</p>
                </div>
            </li>*/}
            
            <li className="nav-item ms-3">
              <span to="#" className='search-open-btn'>
                <form className='search-bar' ref={searchBoxRef} onSubmit={handleSearchForm}>
                  <div className="input-box">
                    <div className={`${searchBoxOpen ? 'results-active' : ''} ${searchList.length > 0 ? 'loaded' : ''}`}>
                      <input onChange={handleSearch} value={searchTerm.query} name="query" type="text" id='search_input' tabIndex={1} placeholder="Enter your search key ..." />
                      <div className='search-results'>
                        <ul>
                            {searchList.map((i, n) => <li key={i.ItemId} className={`${activeListItem === n && 'active'}`}><Link to={`/productPage/${i.ItemId}`} onClick={() => setSearchList([])}>{i.Description}</Link></li>)}
                        </ul> 
                      </div>
                    </div>
                      <label htmlFor="search_input">
                          <i className='bx bx-search header-login ms-3' onClick={() => setSearchBoxOpen(!searchBoxOpen)}></i>
                      </label>
                  </div>
                </form>
              </span>
            </li>
            {MODULES[compCode]?.includes('PHARMACY') || <li className="nav-item">
                <Link to='/cartPage' className='' onClick={() => setMenuOpen(false)}>
                  <i className='bx bx-cart-alt h2 mb-0 mt-2 position-relative'>
                    <span id="cart-badge" className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" style={{fontSize: '11px', fontFamily: 'Poppins', display: totalCartItems > 0 ? 'block' : 'none'}}>{totalCartItems}</span>
                  </i>
                </Link>
            </li>}
            <li className="nav-item" style={{display: isLoggedIn ? 'none' : ''}}>
                <button className="nav-link header-login" to="#" onClick={() => loginModalAction(true, 'POLYCLINIC')}>Join us</button>
            </li>
            <li className="nav-item" style={{display: isLoggedIn ? 'none' : ''}}>
                <button className="nav-link header-login" to="#" onClick={() => loginModalAction(true, 'PATIENT')}>Login </button>
            </li>
            <li className="nav-item">
                <a href='https://erp.gsterpsoft.com/Login.aspx' target={'_blank'} rel="noreferrer"><i className="bx bx-desktop header-login" style={{fontSize: '18px', cursor: 'pointer'}}></i></a>
            </li>
            <li className="nav-item dropdown has-arrow logged-item" style={{display: isLoggedIn ? '' : 'none'}}>
              <Link to="#" className="dropdown-toggle nav-link align-items-center" data-toggle="dropdown" aria-expanded="false">
                <span className="user-img">
                  <img className="rounded-circle" src="/img/user_unknown.png" width="31" alt="Darren Elder"/>
                </span>
                <h6 className="ms-2 mb-0">{userInfo.Name}</h6>
              </Link>
              <div className="dropdown-menu dropdown-menu-right">
                <div className="user-header d-flex align-items-center">
                  <div className="avatar avatar-sm">
                    <img src="/img/user_unknown.png" alt="User" className="avatar-img rounded-circle"/>
                  </div>
                  <div className="user-text">
                    <h6>{userInfo.Name}</h6>
                    {/*<p className="text-muted mb-0">Doctor</p> */}
                  </div>
                </div>
                <Link className="dropdown-item" to="/profile">
                  <i className='bx bx-user-circle h3 mb-0 me-2'></i> Profile
                </Link>
                <Link className="dropdown-item" to="/dashboard">
                  <i className='bx bx-tachometer h3 mb-0 me-2'></i> Dashboard
                </Link>
                {MODULES[compCode]?.includes('PHARMACY') || <Link className="dropdown-item" to="/myOrders">
                  <i className='bx bx-cart-alt h3 mb-0 me-2'></i> My Orders
                </Link>} 
                <span onClick={handleLogOut} className="dropdown-item" to="#">
                  <i className='bx bx-log-out-circle h3 mb-0 me-2'></i> Logout
                </span>
                {/* <a href='/' className="dropdown-item" to="#">
                  <i className='bx bx-log-out-circle h3 mb-0 me-2'></i> Logout
                </a> */}
              </div>
            </li>
        </ul>
      </nav>
      <div className={`menu-backdrop ${menuOpen ? 'active' : ''}`} onClick={() => setMenuOpen(false)}></div>
    </header>
    <div className={`main-menu-wrapper ${menuOpen ? 'menu-opened' : ''}`}>
    <div className="menu-header">
            <Link to="/" className="menu-logo">
                {
                  companyLogo.includes(compInfo.LogoUrl.split('.')[0]) ? 
                  <img src={`/img/logo/${compInfo.LogoUrl}`} className="img-fluid logo" alt={compInfo.COMPNAME}/> :
                  <img src={'/img/logo/opd2.png'} className="img-fluid logo rounded-circle" alt={compInfo.COMPNAME}/>
                }
            </Link>
            <span id="menu_close" className="menu-close" to="#" onClick={() => setMenuOpen(false)}>
                <i className="fas fa-times"></i>
            </span>
        </div>
        <Menu isLoggedIn={isLoggedIn} />
    </div>
    <ConnectedLoginModal />
    </>
  )
}


const mapStateToProps = (state) => {
  return { compCode: state.compCode, compInfo: state.compInfo, loginModalOpen: state.loginModalOpen, isLoggedIn: state.isLoggedIn, userInfo: state.userInfo, cart: state.cart, siteData: state.siteData };
}

export default connect(mapStateToProps, {loginModalAction, loginStatusAction, siteDataAction, userInfoAction, compInfoAction, compCodeAction, loaderAction, modalAction})(Header);


const LoginModal = ({ compCode, loginModalOpen, loginModalAction, isLoggedIn, loginStatusAction, userInfo, userInfoAction, loaderAction, bookingInfo }) => {

  const [statesList, setStatesList] = useState([{Description: 'West Bengal', CodeId: 3}]); 

  const history = useHistory();

  useEffect(() => {
    const getStates = async () => {
      loaderAction(true);
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/Values/1`);
      loaderAction(false);
      let states = res.data.map(i => ({Description: i.Description, CodeId: i.CodeId}));
      setStatesList(states);
    }
    getStates();
  },[loaderAction])

  useEffect(() => {
    setEnteredOTP('');                // Reset these field on whnever form opens or closes.
    setGeneratedOTP('init_random_otp');        // Initialize with random number to prevent submission with otp === ''.
  },[loginModalOpen.status])

  useEffect(() => {
    handleUserType(loginModalOpen.mode);
  },[loginModalOpen.mode])
  
  useEffect(() => {                             // RESET FORM - Close otp personal fields when user reopens the login modal.
    setShowNumberSubmitBtn(true);
    setShowOtpField(false);
    setLoginError({status: false, message: ''});                      // Reset loginError to false on every page reRender.
    setShowPersonalFields(false);
    setShowOrgFields(false);
  },[loginModalOpen.status])

  const [alertVisible, setAlertVisible] = useState(false);
  const [forgotPasswordModal, setForgotPasswordModal] = useState({isOpen: false, passwordRecoveryNumber: ''});
  const [refNumber, setRefNumber] = useState('');
  const [loginData, setLoginData] = useState({ phone: '', password: '', EncCompanyId: '' });
  const [registerData, setRegisterData] = useState({
    Salutation: '',
    Name: '',
    EncCompanyId: '',
    PartyCode: '',
    RegMob1: '',
    Gender: '',
    GenderDesc: '',
    Address: '',
    Age: '',
    AgeMonth: '',
    AgeDay: '',
    UserPassword: '',
    UserType: '',                // will set to loginModalOpen.mode in dumpAllRegisterData() on each page mount.
    Qualification: '',
    RegNo: '',
    SpecialistId: 0,
    UserId: 0,
    PartyId: 0,

    State: '3',
    City: '',
    Pin: '',
    Address2: '',

    DOB: new Date().toLocaleDateString('fr-CA'),
    AnniversaryDate: new Date().toLocaleDateString('fr-CA'),
    Aadhaar: '',
    IsDOBCalculated: 'N',

    compName: '',
    compAddress: '',
    compState: '',
    compPin: '',
    compPhone1: '',
    compPhone2: '',
    compMail: '',
  })


 const [genderData, genderDataLoading, genderDataError] = useFetch(`${process.env.REACT_APP_BASE_URL}/api/Values`, compCode);
 const [showOtpField, setShowOtpField] = useState(false);
 const [showPersonalFields, setShowPersonalFields] = useState(false);
 const [showOrgFields, setShowOrgFields] = useState(false);
 const [showNumberSubmitBtn, setShowNumberSubmitBtn] = useState(true);
 const [loginError, setLoginError] = useState({status: false, message: ''});
 const [generatedOTP, setGeneratedOTP] = useState('init_random_otp');
 const [enteredOTP, setEnteredOTP] = useState('asdfsdff');
 const [specializations, setSpecializations] = useState({isFieldOpen: false, data: []});
 const [isTabActive, setTabActive] = useState('register');

 useEffect(() => {
  if (!isLoggedIn) {                        
    // dumpAllLoginData();                                                                       // Currently Page reload on logout is making whole website reset, hence turning off for now.           
    // dumpAllRegisterData();                                                                    // Reset these data whenever sign in status changes. Clear all user data from the form when he logs out.  
    setLoginData(pre => ({...pre, EncCompanyId: compCode}));                                     // set company code on both forms.
    setRegisterData(pre => ({...pre, EncCompanyId: compCode}));          
  } else {
    setLoginData(preValue => {              // Populate the forms with existing user data if he is logged in.
      return { ...preValue, phone: userInfo.RegMob1, password: '', EncCompanyId: userInfo.EncCompanyId };
    });
    setRegisterData((pre => ({             
      ...pre,
      Name: userInfo.Name,
      RegMob1: userInfo.RegMob1,
      UserId: 0,
      UserType: userInfo.UserType,
      PartyCode: userInfo.PartyCode,
      EncCompanyId: userInfo.EncCompanyId,
      Age: userInfo.Age,
      AgeDay: userInfo.AgeDay,
      AgeMonth: userInfo.AgeMonth,
      Gender: userInfo.Gender,
      GenderDesc: userInfo.GenderDesc,
      MPartyCode: userInfo.MPartyCode,
      Address: userInfo.Address,
      Qualification: userInfo.Qualification,
      SpecialistDesc: userInfo.SpecialistDesc,
      RegNo: userInfo.RegNo,
      State: userInfo.State,                          
      City: userInfo.City,
      Pin: userInfo.Pin,
      Address2: userInfo.Address2,
      UHID: userInfo.UHID,
      MemberId: userInfo.MemberId,
      PartyId: userInfo.PartyId,
      Salutation: userInfo.Salutation,

      DOB: userInfo.DOB,
      AnniversaryDate: userInfo.AnniversaryDate,
      Aadhaar: userInfo.Aadhaar,
      IsDOBCalculated: userInfo.IsDOBCalculated,

      compName: userInfo.compName ? userInfo.compName : '',
      compAddress: userInfo.compAddress ? userInfo.compAddress : '',
      compState: userInfo.compState ? userInfo.compState : '',
      compPin: userInfo.compPin ? userInfo.compPin : '',
      compPhone1: userInfo.compPhone1 ? userInfo.compPhone1 : '',
      compPhone2: userInfo.compPhone2 ? userInfo.compPhone2 : '',
      compMail: userInfo.compMail ? userInfo.compMail : '',
    })))
  }
}, [isLoggedIn, userInfo]);

 // LOGIN FORM FUNCTIONS..

 const handleLoginInput = (e) => {
   const {name, value} = e.target;
   setLoginData(preValue => {
     return {...preValue, [name]: value};
   });
 }

 const handleLoginFormSubmit = (e) => {
   e.preventDefault();
   makeLoginRequest({ phone: loginData.phone, password: loginData.password, companyCode: compCode });
 }
 
 const makeLoginRequest = async (params) => {
   loaderAction(true);
   const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/UserAuth?UN=${params.phone}&UP=${params.password}&CID=${params.companyCode}`);
   loaderAction(false);
   if (res.data.UserId === 0) {
     setLoginError({status: true, message: 'The username or password is incorrect.'});
   } else {
     let userLoginData = {
        Name: res.data.UserFullName,
        RegMob1: params.phone,
        UserId: res.data.UserId,
        UserType: res.data.UserType,
        PartyCode: res.data.PartyCode,
        EncCompanyId: params.companyCode,
        Age: res.data.Age,
        AgeDay: res.data.AgeDay,
        AgeMonth: res.data.AgeMonth,
        Gender: res.data.Gender,
        GenderDesc: res.data.GenderDesc,
        MPartyCode: res.data.MPartyCode,
        Address: res.data.Address,
        Qualification: res.data.Qualification,
        SpecialistDesc: res.data.SpecialistDesc,
        RegNo: res.data.RegNo ? res.data.RegNo : '',
        State: res.data.State,                          
        City: res.data.City,
        Pin: res.data.Pin,
        Address2: res.data.Address2,
        UHID: res.data.UHID,
        MemberId: res.data.MemberId,
        PartyId: res.data.PartyId,
        Salutation: res.data.Salutation,

        DOB: res.data.DOB,
        AnniversaryDate: res.data.AnniversaryDate,
        Aadhaar: res.data.Aadhaar,
        IsDOBCalculated: res.data.IsDOBCalculated,

        compName: res.data.compName ? res.data.compName: '',
        compAddress: res.data.compAddress ? res.data.compAddress: '',
        compState: res.data.compState ? res.data.compState: '',
        compPin: res.data.compPin ? res.data.compPin: '',
        compPhone1: res.data.compPhone1 ? res.data.compPhone1: '',
        compPhone2: res.data.compPhone2 ? res.data.compPhone2: '',
        compMail: res.data.compMail ? res.data.compMail: '',
      };

      localStorage.setItem("userLoginData", encrypt({ phone: params.phone, password: res.data.UserPassword, compCode: params.companyCode }));
      userInfoAction(userLoginData);

      const newbookingData = { 
        ...bookingInfo,
        Salutation: res.data.Salutation,
        Name: res.data.Name,
        EncCompanyId: userInfo.selectedCompany.EncCompanyId,
        PartyCode: userInfo.selectedCompany.CompUserPartyCode,
        MPartyCode: userInfo.selectedCompany.CompUserMPartyCode,
        RegMob1: res.data.RegMob1,
        Gender: res.data.Gender ,
        GenderDesc: res.data.GenderDesc ,
        Address: res.data.Address ,
        Age: res.data.Age,
        AgeMonth: res.data.AgeMonth,
        AgeDay: res.data.AgeDay,
        State: res.data.State ,
        City: res.data.City,
        Pin: res.data.Pin,
        Address2: res.data.Address2,
        AnniversaryDate: res.data.AnniversaryDate,
        Aadhaar: res.data.Aadhaar ,
        UserId: res.data.UserId,
        UHID: res.data.UHID,
        MemberId: res.data.MemberId,
        Country: res.data.Country,
      }
      handleRedirect(res.data.UserType, newbookingData);

      loginModalAction(false, 'PATIENT');
      loginStatusAction(true);
    }
 }



 const dumpAllLoginData = () => {
    setLoginData(preValue => {
      return {
        ...preValue,
        phone: '',
        password: '',
      }
    })
 }

  const handleRegistrationInput = (e) => {
    const {name, value} = e.target;
    setRegisterData(preValue => {
      return {...preValue, [name]: value};
    });
  }

  const handleRegisterFormSubmit = async (e) => {
    e.preventDefault();
    console.log(registerData);
    if (enteredOTP !== '') {                                              // (generatedOTP === enteredOTP) 
      let status = await makeRegisterationRequest(registerData);         // Using await to wait for final the result after complete running of the
        if (status) {                                                    // Waiting is required otherwise status === pending instead of true/false.
          // userInfoAction(registerData);                                  // Asyncronous code (api request) present in the makeResiterationRequest function. 

          const newbookingData = { 
            ...bookingInfo,
            Salutation: userInfo.Salutation,
            Name: userInfo.Name,
            EncCompanyId: userInfo.selectedCompany.EncCompanyId,
            PartyCode: 0,
            MPartyCode: '',
            RegMob1: userInfo.RegMob1,
            Gender: userInfo.Gender,
            GenderDesc: userInfo.GenderDesc,
            Address: userInfo.Address,
            Age: userInfo.Age,
            AgeMonth: userInfo.AgeMonth,
            AgeDay: userInfo.AgeDay,
            State: userInfo.State,
            City: userInfo.City,
            Pin: userInfo.Pin,
            Address2: userInfo.Address2,
            AnniversaryDate: userInfo.AnniversaryDate,
            Aadhaar: userInfo.Aadhaar,
            UserId: userInfo.UserId,                              // inserting UserInfo received in makeRegisterationRequest and stored userInfo.UserId.
            UHID: userInfo.UHID,
            MemberId: userInfo.MemberId,
            Country: 1,
          }

          handleRedirect(registerData.UserType, newbookingData);     

          // handleRedirect(registerData.UserType, Object.assign(userInfo, registerData));     // Mannually concat both objects since userInfoAction is unable to update userInfo before passing
                                                                                            // it to the makeBookingRequest due to asyncronous updating nature of state update.
          loginModalAction(false, loginModalOpen.mode);                                     // update userInfo here to avoid messing with makeBookingRequest.
          loginStatusAction(true);
        } else {
          alert('An Error Occured, Please try again later.');
          return;
        }
    } else {
      alert('Wrong OTP, enter correct OTP');
    }
  }

  const handleRedirect = (userType, bookingData) => {
    switch (userType) {
      case 'PATIENT':
        if (!bookingData.TimeSlotId) {
          // history.push('/');
        } else {
          // makeBookingRequest(bookingData);        
          // setTimeout(() => { history.push('/dashboard') }, 1000);
        }
        break;
      default:
        history.push('/dashboard');
        break;
    }                                                    
  }

  const compareOtp = () => {
    
    if (enteredOTP !== '') {
      if (loginModalOpen.mode === "POLYCLINIC") {
        setShowOrgFields(true);
      } else {
        setShowPersonalFields(true);
      }
      setShowOtpField(false);
    } else {
      alert('Wrong OTP, enter correct OTP');
    }
  }

  const resendOtp = () => {
    revealOtpField();
  }

  const handleOrgSubmit = () => {
    const res = true;
    if (res) {
      setShowOrgFields(false);
      setShowPersonalFields(true);
    } else {
      console.log('unexpected error.');
    }
  }

  const makeRegisterationRequest = async (params) => {
    console.log(params);
    if (!params.UserType) return alert('Error, no user type received.');
    try {
      loaderAction(true);
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/UserReg`, params);
      loaderAction(false);
      if (res.data[0] === 'Y') {
        localStorage.setItem("userLoginData", encrypt({ phone: params.RegMob1, password: params.UserPassword, compCode: params.EncCompanyId }));
        // localStorage.setItem("userLoginData", JSON.stringify({ phone: params.RegMob1, password: params.UserPassword, compCode: params.EncCompanyId }));
        userInfoAction({ ...params, UserId: parseInt(res.data[1]) });        // received UserId is string type hence converting it to integer because everywhere (received login data) else it's used as integer
        return true;                                                         // 'UserId' !== 'UserId which can cuase wrong output in filtering just like done in getMembersList function.
      }      
    } catch (err) {
      console.log(err);
      return false;
    }
  }  

  // const makeBookingRequest = async (params) => {                 // not using currently, safe to delete.
  //   console.log(params);
  //   if (!params.UserId) return alert('Something went wrong, try again later. No user Id received: H');
  //   loaderAction(true);
  //   const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/Appointment`, params);
  //   loaderAction(false);
  //   if (res.status === 200) {
  //     setRefNumber(res.data);
  //     loginModalAction(false, 'PATIENT');
  //     setAlertVisible(true);
  //     return;
  //   }
  //   alert('An Error Occured, Refresh and Try again later.');
  // }

  const dumpAllRegisterData = () => {
    setRegisterData(preValue => {
      return {
        ...preValue,
        Salutation: '',
        Name: '',
        // EncCompanyId: '',                  // Do not reset EncCompanyId which is compCode for the current user which we got from URL paramenters.
        PartyCode: '',
        RegMob1: '',
        Gender: '',
        GenderDesc: '',
        Address: '',
        Age: '',
        AgeMonth: '',
        AgeDay: '',
        UserPassword: '',   
        UserType: loginModalOpen.mode, 
        Qualification: '',
        RegNo: '',
        SpecialistId: 0,
        State: '3',                           // taking string as select element returns value strings only.
        City: '',
        Pin: '',
        Address2: '',
        PartyId: 0,

        DOB: new Date().toLocaleDateString('fr-CA'),
        AnniversaryDate: new Date().toLocaleDateString('fr-CA'),
        Aadhaar: '',
        IsDOBCalculated: 'N',
        UHID: '',
        MemberId: '',

        compName: '',
        compAddress: '',
        compState: '',
        compPin: '',
        compPhone1: '',
        compPhone2: '',
        compMail: '',
      }
    });
  }

  const makeOtpRequest = async () => {
    loaderAction(true);
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/UserReg/0?name=Subscriber&mob=${registerData.RegMob1}`);
    loaderAction(false);
    if (res.status === 200) {
      setGeneratedOTP(res.data);
      console.log(`${res.data}`.split('').reverse().join(''));
      return;
    }
    alert('An Error Occured, Try again later.');
  }

  // const makeOtpRequest = () => {
  //   let otp = Math.ceil(Math.random()*10000);
  //   alert(otp);
  //   setGeneratedOTP(`${otp}`);
  //   console.log(`${otp}`.split('').reverse().join('')); 
  // }

  const toggleGender = (e) => {
    let val = e.target.value;
    let female = ['Ms', 'Mrs', 'Miss'];
    if (female.includes(val)) {
      setRegisterData(preValue => {
        return {...preValue, Gender: 105, GenderDesc: 'Female'};
      });
    } else {
      setRegisterData(preValue => {
        return {...preValue, Gender: 104, GenderDesc: 'Male'};
      });
    }
  }

  const handleFormClose = () => {
    loginModalAction(false, loginModalOpen.mode);                    // Close the form.
    dumpAllRegisterData();                   // Dump all entered Register data.
  }

  const checkExistingUser = async () => {
    if (registerData.RegMob1.length > 9) {
      loaderAction(true);
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/UserReg?UN=${registerData.RegMob1}`);
      loaderAction(false);
      if (res.data === 'Y') {
        setLoginError({status: true, message: 'This number is already registered.'});
        setLoginData(preValue => {
          return { ...preValue, phone: registerData.RegMob1 }
        })
        setRegisterData(preValue => {
          return { ...preValue, RegMob1: '' }
        })
        setTabActive('login');
      } else {
        setLoginError({status: false, message: ''});
        revealOtpField();
        setShowNumberSubmitBtn(false);
      }
    }
  }

  const revealOtpField = () => {
    if (registerData.RegMob1.length > 9 && !loginError.status ) {
      setShowOtpField(true);
      makeOtpRequest(); 
    }
  }

  const showReference = () => {
    return <span className="text-info">Please keep your Reference(H) No: <span className='text-danger ms-2'>{refNumber}</span></span>;
  }

  const userTypes = ['POLYCLINIC', 'DOCTOR', 'PROVIDER', 'COLLECTOR'];

  const handleUserType = (item) => {
    setRegisterData((preValue) => {
      return {...preValue, UserType: item}
    });
    loginModalAction(loginModalOpen.status, item);
    if (item === 'DOCTOR' || item === 'POLYCLINIC') {
      getSpecializations();
    } else {
      setSpecializations(preValue => {
        return {...preValue, isFieldOpen: false}
      });
    }
  } 

  const getSpecializations = async () => {
    loaderAction(true);
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/Values?CID=${compCode}`, {});
    loaderAction(false);
    if (res.data.length > 0) {
      setSpecializations({isFieldOpen: true, data: res.data});
    } else {
      setSpecializations({isFieldOpen: true, data: ['no data found']});
    }
  }

  const handleForgotPasswordForm = (e) => {
    e.preventDefault();
    sendPassword();
  }

  const sendPassword = async () => {
    loaderAction(true);
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/UserAuth/0?UN=${forgotPasswordModal.passwordRecoveryNumber}&CID=${compCode}&Type=FP`, {});
    loaderAction(false);
    if (res.data === 'Y') {
      setLoginError({status: true, message: 'Your Password has been sent to your registered mobile number. please check !'});
    } else {
      setLoginError({status: true, message: 'This number is not Registered.'});
    }
  }

  const ForgotPassword = () => {
    return (
      <form onSubmit={handleForgotPasswordForm}>
        <div className="row gx-2">
          <div className="col-12">
            <div className="form-group form-focus focused" id="forgotPassword">
              <label className="focus-label"><b className='text-danger'>* </b>Mobile Number</label>
              <input name="passwordRecoveryNumber" onChange={(e) => handleNumberInputs(e, setForgotPasswordModal)} value={forgotPasswordModal.passwordRecoveryNumber} required className="form-control floating" tabIndex={1} id="password_recovery_number" maxLength={10} />
            </div>
            <p className="text-danger" style={{display: loginError.status ? 'block' : 'none'}}>{loginError.message}</p>
            <button type="submit" className="d-block btn btn-primary ms-auto" style={{"minWidth": "9rem", "fontSize": "0.95rem"}}>GET PASSWORD</button>
          </div>
        </div>
      </form>
    )
  }

  const handleForgotPasswordModal = (item) => {
    setForgotPasswordModal({ isOpen: item, passwordRecoveryNumber: ''});
    setLoginError({status: false, message: ''});
  }

  const handleDate = (props) => {
    const { Age, AgeMonth, AgeDay, currField, currValue }  = props;

    if (currField === 'Age') {
      if (currValue !== '' && AgeDay !== '' && AgeMonth !== '') {
        const calculatedDOB = createDate(AgeDay, AgeMonth, currValue);
        setRegisterData(pre => ({...pre, DOB: calculatedDOB, IsDOBCalculated: 'Y'}));
      }
    } else if (currField === 'AgeDay') {
      if (Age !== '' && currValue !== '' && AgeMonth !== '') {
        const calculatedDOB = createDate(currValue, AgeMonth, Age);
        setRegisterData(pre => ({...pre, DOB: calculatedDOB, IsDOBCalculated: 'Y'}));
      }
    } else if (currField === 'AgeMonth') {
      if (Age !== '' && AgeDay !== '' && currValue !== '') {
        const calculatedDOB = createDate(AgeDay, currValue, Age);
        setRegisterData(pre => ({...pre, DOB: calculatedDOB, IsDOBCalculated: 'Y'}));
      }
    }
  }

  const handleNumberInputsWithDate = (e, setStateName) => {
    const {name, value} = e.target;
    const re = /^[0-9\b]+$/;
    if (value === '' || re.test(value)) {
      setStateName(preValue => {
         return {...preValue, [name]: value};
      });
      let currValues = { Age: registerData.Age, AgeMonth: registerData.AgeMonth, AgeDay: registerData.AgeDay, currField: name, currValue: value };
      handleDate(currValues);
    }
  }

  const renderLoginModal = () => {
    return (
      <div id='loginModal' className='d-flex flex-column flex-lg-row'>
        <div className='notes'>
          <ul>
            <li style={{'--clr': 'orangered'}}>
              <p><i className='bx bx-check-circle'></i> Login or Register </p>
            </li>
            <li style={{'--clr': '#00b900'}}>
              <p><i className='bx bx-check-circle'></i> Book Doctor Appointment</p>
            </li>
            <li style={{'--clr': '#03A9F4'}}>
              <p><i className='bx bx-check-circle'></i> Book Lab Tests</p>
            </li>
            <li style={{'--clr': '#d143e9'}}>
              <p><i className='bx bx-check-circle'></i> View Medical Records</p>
            </li>
            <li style={{'--clr': '#905bed'}}>
              <p><i className='bx bx-check-circle'></i> Add Family Members</p>
            </li>
            <li style={{'--clr': '#FF9800'}}>
              <p><i className='bx bx-check-circle'></i> And Much More.....</p>
            </li>
          </ul>
        </div>
        <div>
          <ul className="nav nav-tabs pb-2 border-0" role="tablist">
            <li className="nav-item w-50" role="presentation">
              <button type="button" onClick={() => {setTabActive('login'); setLoginError({status: false, message: ''})}} id="tabFade-1" className={`nav-link w-100 ${isTabActive === 'login' ? 'active' : ''}`}>LOGIN</button>
            </li>
            <li className="nav-item w-50" role="presentation">
              <button type="button" onClick={() => {setTabActive('register'); setLoginError({status: false, message: ''})}} id="tabFade-2" className={`nav-link w-100 ${isTabActive === 'register' ? 'active' : ''}`}>REGISTRATION</button>
            </li>
          </ul>
          <div className="tab-content pt-1">
            <div id="tabFade-pane-1" className={`tab-pane fade ${isTabActive === 'login' ? 'show active' : ''}`}>
              <form onSubmit={handleLoginFormSubmit} className="pt-2">
                <div className="row gx-2">
                  <div className="col-12">
                    <div className="form-group form-focus focused" id="lblMobile1">
                      <label className="focus-label"><b className='text-danger'>* </b>Mobile Number</label>
                      <input name="phone" onChange={(e) => handleNumberInputs(e, setLoginData)} value={loginData.phone} required className="form-control floating" tabIndex={1} id="loginPhone" maxLength={10} />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group form-focus focused">
                      <label className="focus-label"><b className='text-danger'>* </b>Password</label>
                      <input onChange={handleLoginInput} value={loginData.password} required name="password" className="form-control floating" type="password" tabIndex={1} autoComplete='off' />
                    </div>
                    <p className="text-danger" style={{display: loginError.status ? 'block' : 'none'}}>{loginError.message}</p>
                    <Link to='' className="text-info cursor" onClick={() => {loginModalAction(false, loginModalOpen.mode); handleForgotPasswordModal(true);}}>Forgot Password ?</Link>
                    <button type="submit" className="d-block btn btn-primary ms-auto" style={{"minWidth": "9rem", "fontSize": "0.95rem"}}>Submit</button>
                  </div>
                </div>
              </form>
            </div>
            <div id="tabFade-pane-2" className={`tab-pane fade ${isTabActive === 'register' ? 'show active' : ''}`}>
              <div className="row" id="divEnqDataContent">
                <form className="bg-white rounded pt-2" onSubmit={handleRegisterFormSubmit} id="registrationForm">
                  <div className="col-md-12">
                    <div className="row gx-2 mb-3 justify-content-between mx-0" id='userTypeTabs' style={{display: registerData.UserType !== 'PATIENT' ? 'flex' : 'none'}}>
                      {customTabsButtons(userTypes, registerData.UserType, handleUserType)}
                    </div>
                    <div className="row gx-2">
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group form-focus focused" id="lblMobile1">
                          <label className="focus-label"><b className='text-danger'>* </b>Mobile Number</label>
                          <input name="RegMob1" value={registerData.RegMob1} onChange={(e) => handleNumberInputs(e, setRegisterData)} required className="form-control floating" tabIndex={1} id="txtMobileNo1" maxLength={10} />
                        </div>
                        <p className="text-danger" style={{display: loginError.status ? 'block' : 'none'}}>{loginError.message}</p>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <span className={`btn btn-primary btnSave ms-auto fw-bold ${loginError.status ? 'disabled' : ''}`} onClick={checkExistingUser} style={{width: "8em", display: showNumberSubmitBtn ? 'block' : 'none'}} tabIndex={1}>NEXT</span>
                      </div>
                    </div>

                    <div className="row gx-2" style={{display: showOtpField ? 'flex' : 'none'}}>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group form-focus focused mb-0">
                          <label className="focus-label">ENTER YOUR OTP</label>
                          <input onChange={(e) => setEnteredOTP(e.target.value)} value={enteredOTP} className="form-control floating" type="text" id="txtPHeight" tabIndex={1} autoComplete='off' />
                          <span className='mark text-sm' onClick={resendOtp}  style={{width: "10rem"}} tabIndex={1}>Resend OTP</span>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12" style={{display: showOtpField ? 'block' : 'none'}}>
                        <span className='btn btn-primary btnSave ms-auto fw-bold d-block' onClick={compareOtp}  style={{width: "8em"}} tabIndex={1}>SUBMIT OTP</span>
                      </div>
                    </div>

                    <div style={{display: showOrgFields && loginModalOpen.mode === 'POLYCLINIC' ? 'block' : 'none'}}>
                      <h4 className="card-title">Organisation Information:-</h4>
                      <div className="row gx-2">
                        <div className="col-md-6">
                          <div className="form-group form-focus focused">
                            <label className="focus-label">Organisation Name</label>
                            <input name="compName" value={registerData.compName} onChange={handleRegistrationInput} className="form-control floating" tabIndex={1} type="text" autoComplete='off' />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-focus focused" id="lblMobile1">
                            <label className="focus-label">Organisation Mobile Number 1</label>
                            <input name="compPhone1" onChange={(e) => handleNumberInputs(e, setRegisterData)} value={registerData.compPhone1} className="form-control floating" tabIndex={1} maxLength={10} autoComplete='off' />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-focus focused" id="lblMobile1">
                            <label className="focus-label">Organisation Mobile Number 2</label>
                            <input name="compPhone2" onChange={(e) => handleNumberInputs(e, setRegisterData)} value={registerData.compPhone2} className="form-control floating" tabIndex={1} maxLength={10} autoComplete='off' />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-focus focused" id="lblMobile1">
                            <label className="focus-label">Organisation Email</label>
                            <input name="compMail" onChange={handleRegistrationInput} value={registerData.compMail} className="form-control floating" tabIndex={1} type='text' autoComplete='off'/>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-focus focused">
                            <label className="focus-label">Organisation Address</label>
                            <input name="compAddress" value={registerData.compAddress} onChange={handleRegistrationInput} className="form-control floating" tabIndex={1} type="text" autoComplete='off'/>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-focus focused">
                            <label className="focus-label">Organisation State</label>
                            <select name="compState" value={registerData.compState} onChange={handleRegistrationInput} tabIndex={1} className="form-control floating">
                              <option value="">-Select-</option>
                              {statesList.map(item => (<option key={item.CodeId} value={parseInt(item.CodeId)}>{item.Description}</option>))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-focus focused">
                            <label className="focus-label">Organisation Pin Code</label>
                            <input name="compPin" value={registerData.compPin} onChange={(e) => handleNumberInputs(e, setRegisterData)} className="form-control floating" tabIndex={1} type='text' maxLength={6}  autoComplete='off' />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <span className="btn btn-primary btnSave ms-auto fw-bold d-block" onClick={handleOrgSubmit} tabIndex="1" style={{width: "10rem"}}>NEXT</span>
                        </div>
                      </div>
                    </div>

                    <div style={{display: showPersonalFields ? 'block' : 'none'}}>
                      <h4 className="card-title">Personal Information:-</h4>
                      <div className="row gx-2">
                        <div className="col-4">
                          <div className="form-group form-focus focused">
                            <label className="focus-label"><b className='text-danger'>* </b>Salutation</label>
                            <select name="Salutation" value={registerData.Salutation} required onChange={(e) => {handleRegistrationInput(e); toggleGender(e);}} id="ddlSalutation" tabIndex={1} className="form-control">
                              <option value="">-Select-</option>
                              <option value="Dr">Dr</option>
                              <option value="Mr">Mr</option>
                              <option value="Ms">Ms</option>
                              <option value="Mrs">Mrs</option>
                              <option value="Miss">Miss</option>
                              <option value="BabyOf">Baby Of</option>
                              <option value="Master">Master</option>
                              <option value="Baby">Baby</option>
                              <option value="Md">Md.</option>
                              <option value="Prof">Prof.</option>
                              <option value="Rev">Rev.</option>
                              <option value="Sk">Sk.</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-8">
                          <div className="form-group form-focus focused">
                            <label className="focus-label"><b className='text-danger'>* </b>Name</label>
                            <input name="Name" value={registerData.Name} onChange={handleRegistrationInput} className="form-control floating" tabIndex={1} type="text" required/>
                          </div>
                        </div>
                      </div>
                      <div className="row gx-1 gx-md-2">
                        <div className="col-3">
                          <div className="form-group form-focus focused">
                            <label className="focus-label"><b className='text-danger'>* </b>Gender</label>
                            <select name="Gender" value={registerData.Gender} onChange={handleRegistrationInput} required tabIndex={1} className="form-control floating">
                              <option value="">-Select-</option>
                              {genderData.map(item => (<option key={item.CodeId} value={item.CodeId}>{item.Description}</option>))}
                            </select>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="form-group form-focus focused">
                            <label className="focus-label"><b className='text-danger'>* </b>DOB</label>
                            <DatePicker 
                              selected={new Date(registerData.DOB)}
                              onChange={(date) => setRegisterData(pre => ({ ...pre, IsDOBCalculated: 'N', Age: getDuration(date?.toLocaleDateString('fr-CA')).years, AgeMonth: getDuration(date?.toLocaleDateString('fr-CA')).months, AgeDay: getDuration(date?.toLocaleDateString('fr-CA')).days, DOB: date ? date.toLocaleDateString('fr-CA') : new Date().toLocaleDateString('fr-CA')}))}
                              showYearDropdown
                              dateFormatCalendar="MMMM"
                              yearDropdownItemNumber={100}
                              scrollableYearDropdown
                              className="form-control"
                              dateFormat="dd/MM/yyyy"
                            />
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group form-focus focused">
                            <label className="focus-label"><b className='text-danger'>* </b>Years</label>
                            <input name="Age" value={registerData.Age} onChange={(e) => handleNumberInputsWithDate(e, setRegisterData)} required className="form-control floating" tabIndex={1} type='text' maxLength={2} id="txtPtAge" />
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group form-focus focused">
                            <label className="focus-label">Months</label>
                            <input name="AgeMonth" value={registerData.AgeMonth} onChange={(e) => handleNumberInputsWithDate(e, setRegisterData)} className="form-control floating" tabIndex={1} type='text' maxLength={2} id="txtPtAgeMonth"/>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group form-focus focused">
                            <label className="focus-label">Days</label>
                            <input name="AgeDay" value={registerData.AgeDay} onChange={(e) => handleNumberInputsWithDate(e, setRegisterData)} className="form-control floating" tabIndex={1} type='text' maxLength={2} id="txtPtAgeDay"/>
                          </div>
                        </div>
                      </div>

                      <div className="row gx-2" style={{display: specializations.isFieldOpen ? 'flex' : 'none'}}>
                        <div className="col-4">
                          <div className="form-group form-focus focused">
                            <label className="focus-label">Qualification</label>
                            <input name="Qualification" value={registerData.Qualification} onChange={handleRegistrationInput} className="form-control floating" tabIndex={1} type="text" id="txtQualification" autoComplete='off' />
                          </div>
                        </div>
                        
                        <div className="col-4">
                          <div className="form-group form-focus focused">
                            <label className="focus-label">Reg. No</label>
                            <input name="RegNo" value={registerData.RegNo} onChange={handleRegistrationInput} className="form-control floating" tabIndex={1} type="text" id="txtRegNo" autoComplete='off'/>
                          </div>
                        </div>

                        <div className="col-4">
                          <div className="form-group form-focus focused">
                            <label className="focus-label">Specialization</label>
                            <select name="SpecialistId" value={registerData.SpecialistId} onChange={handleRegistrationInput} id="SpecialistId" tabIndex={1} className="form-control floating" autoComplete='off'>
                              <option value="">-Select-</option>
                              {specializations.data.map(item => (<option key={item.SubCode} value={item.SubCode}>{item.Description}</option>))}
                            </select>
                          </div>
                        </div>

                      </div>

                      {loginModalOpen.mode === 'PATIENT' && <div className="row gx-2">                    
                        <div className="col-12">
                          <div className="form-group form-focus focused">
                            <label className="focus-label">Aadhaar Number</label>
                            <input name="Aadhaar" value={registerData.Aadhaar} onChange={(e) => handleNumberInputs(e, setRegisterData)} className="form-control floating" maxLength={12} tabIndex={1} type="text" autoComplete='off' />
                          </div>
                        </div>
                      </div>}

                      {loginModalOpen.mode !== 'PATIENT' &&  <div className="row gx-2">                    
                        <div className="col-12">
                          <div className="form-group form-focus focused">
                            <label className="focus-label">Aniversary Date</label>
                            <DatePicker 
                              selected={new Date(registerData.AnniversaryDate)}
                              onChange={(date) => setRegisterData(pre => ({ ...pre, AnniversaryDate: date ? date.toLocaleDateString('fr-CA') : new Date().toLocaleDateString('fr-CA') }))}
                              showYearDropdown
                              dateFormatCalendar="MMMM"
                              yearDropdownItemNumber={100}
                              scrollableYearDropdown
                              className="form-control"
                              dateFormat="dd/MM/yyyy"
                            />
                          </div>
                        </div>
                      </div>}

                      <div className="row gx-2">
                        <div className="col-6">
                          <div className="form-group form-focus focused">
                            <label className="focus-label">Apartment / Flat no. (optional)</label>
                            <input name="Address2" value={registerData.Address2} onChange={handleRegistrationInput} tabIndex={1} className="form-control floating" />
                          </div>
                        </div>                      
                        <div className="col-6">
                          <div className="form-group form-focus focused">
                            <label className="focus-label"><b className='text-danger'>* </b>Address</label>
                            <input name="Address" value={registerData.Address} onChange={handleRegistrationInput} required className="form-control floating" tabIndex={1} type="text" />
                          </div>
                        </div>
                      </div>

                      <div className="row gx-2">
                        <div className="col-4">
                          <div className="form-group form-focus focused">
                            <label className="focus-label"><b className='text-danger'>* </b>State</label>
                            <select name="State" value={registerData.State} onChange={handleRegistrationInput} required tabIndex={1} className="form-control floating">
                              <option value="">-Select-</option>
                              {statesList.map(item => (<option key={item.CodeId} value={parseInt(item.CodeId)}>{item.Description}</option>))}
                            </select>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group form-focus focused">
                            <label className="focus-label"><b className='text-danger'>* </b>City</label>
                            <input name="City" value={registerData.City} onChange={handleRegistrationInput} required className="form-control floating" tabIndex={1} type='text'/>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group form-focus focused">
                            <label className="focus-label"><b className='text-danger'>* </b>Pin Code</label>
                            <input name="Pin" value={registerData.Pin} onChange={(e) => handleNumberInputs(e, setRegisterData)} required className="form-control floating" tabIndex={1} type='text' maxLength={6} />
                          </div>
                        </div>
                      </div>

                      <div className="row gx-2">
                        <div className="col-md-6">
                          <div className="form-group form-focus focused">
                            <label className="focus-label"><b className='text-danger'>* </b>Password</label>
                            <input name="UserPassword" value={registerData.UserPassword} onChange={handleRegistrationInput} className="form-control floating" type="password" tabIndex={1} required autoComplete='off'/>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <p className='text-danger text-sm ps-1'>Enter a strong password to complete Registration and keep it for future logins.</p>
                        </div>
                      </div>                                          
                      <button type="submit" className="btn btn-primary d-block btnSave mx-auto fw-bold" style={{width: "10rem"}} tabIndex={1}>SUBMIT</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
                                                                                      // pass child={ForgotPassword({param1: true})} if have parameters to pass as props. Only function Componets can be passed in this manner. 
                                                                                      // Redux state connected function Components become objects hence they can't be passed like this.
  return (                                                                            // Child passing this way solves the loosing focus issue in passed child compoents which is caused by anwanted reRenders.  
    <>                                                                                   
      <ModalComponent isActive={alertVisible} heading="Thank You for Booking." child={showReference()} handleClose={setAlertVisible}/>
      <ModalComponent isActive={forgotPasswordModal.isOpen} heading="Enter your Registerd mobile number." child={ForgotPassword()} handleClose={handleForgotPasswordModal}/>
      <ModalComponent isActive={loginModalOpen.status} heading="Please Login or Register to continue" child={renderLoginModal()} handleClose={handleFormClose}/>
    </>
  )
}

// ***** LoginModal component is not following mount and unmount pattern which prevents auto reset of the component. this needs to be solved.

const mapStateToPropsTwo = (state) => {
  return { loginModalOpen: state.loginModalOpen, compCode: state.compCode, isLoggedIn: state.isLoggedIn, userInfo: state.userInfo, isLoading: state.isLoading, bookingInfo: state.bookingInfo };
}

export const ConnectedLoginModal = connect(mapStateToPropsTwo, {loginModalAction, loginStatusAction, userInfoAction, loaderAction})(LoginModal);
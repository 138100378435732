import React, { useCallback, useEffect } from 'react';
import { ConnectedLabTestCard, ConnectedPackageCard } from './cards';
import { SliderSectionM } from './mobileView/homeM';
import SliderSection from './sliderSection';
import { BreadCrumb, Spinner } from './utilities';
import { connect } from 'react-redux';
import { siteDataAction } from '../actions';
import axios from 'axios';


const LabTests = ({ siteData, siteDataAction, compCode, isMobile }) => {

  const labTests = [].filter(i => i.Category === 'labTest');
  const healthPakages = [].filter(i => i.Category === 'healthPakage');  
  
  const breadCrumbData = {
    links: [{name: 'Home', link: '/'}, {name: 'Labtests', link: '/labtests'}],
    activeLink: '/labtests'
  }

  const getProductsData = useCallback(async () => {
    // if (siteData.itemMasterCollection.length > 0) return;
    if (siteData.itemMasterCollection[0] !== 'loading') return;
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/Pharma?CID=${compCode}`, {});
      if (res) {
        siteDataAction(res.data); 
      }
  },[compCode, siteData, siteDataAction])

  useEffect(() => {
    getProductsData();
  },[getProductsData])

  const renderSlider = (data, parentId) => {
    const productCategoryItems = data.itemMasterCollection.filter(i => i.Category === parentId).slice(0, 30);   
    const parentCategoryName = data.ParentCategoryList.filter(i => i.Value === parentId.toString())[0]?.Text;
    if (data.itemMasterCollection[0] === 'loading') {
      return <Spinner min_height='28rem' fSize='2rem'/>;
    } else if (productCategoryItems.length === 0) {
      return <div className='text-center my-5 w-100'><h2 className="text-info mark">No Products found !</h2></div>;
    } else {
      if (isMobile) return <SliderSectionM data={productCategoryItems} parentCategoryName={parentCategoryName} parentId={parentId}/>;
      return (
        <div>
          <div className="section-header text-center mt-0">
              <h2 style={{"borderBottom": "2px solid gray", "textTransform": "uppercase", "display": "inline", "letterSpacing": "3px"}}>{parentCategoryName}</h2>
          </div>
          <div className='container-fluid'>
              <SliderSection children={<ConnectedLabTestCard/>} data={productCategoryItems} heading="neurology-slider"/>
          </div>
        </div>
      )
    }
  }

  return (
    <>
      <section className="">
        <BreadCrumb data={breadCrumbData}/>
        <div className='content'>
          {/* {siteData.ParentCategoryList.length !== 0 && <div className='slider-wrapper overflow-hidden'>
              {siteData.ParentCategoryList.map((item, index) => (<div key={index}>{renderSlider(siteData, parseInt(item.Value))}</div>))} 
          </div>} */}
          {
            siteData.itemMasterCollection.length === 0 ? 
            <div className='text-center my-5 w-100'><h2 className="text-info mark">No Integration for this demo version</h2></div> :
            <div className='slider-wrapper overflow-hidden'>
              {siteData.ParentCategoryList.map(item => (<div key={item.Value}>{renderSlider(siteData, parseInt(item.Value))}</div>))} 
            </div>
          }
          {/* <div className='text-center my-5 w-100'><h2 className="text-info mark">No Integration for this demo version</h2></div> */}
        </div>
      </section>
      {/* {renderSlider(siteData, parseInt("8756"))} */}
    </>
  )
}

// export default React.memo(LabTests);

const mapStateToLabTests = (state) => {
  return { siteData: state.siteData, compCode: state.compCode, isMobile: state.isMobile }
}

const MemoizedLabTest = React.memo(LabTests);
export default connect(mapStateToLabTests, {siteDataAction})(MemoizedLabTest);
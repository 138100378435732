import { connect } from "react-redux";
import { userInfoAction, loaderAction, modalAction, compCodeAction } from "../../actions";
import { getDuration, handleNumberInputs } from "../utilities";
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { useFetch, createDate } from "../utilities";
import axios from "axios";

const MemberForm = ({ compCode, loaderAction, modalAction, userInfo, userInfoAction, modalData }) => {

    const [statesList, setStatesList] = useState([{Description: 'West Bengal', CodeId: 3}]);
    // const [startDate, setStartDate] = useState(new Date());
    const [specializations, setSpecializations] = useState({isFieldOpen: false, data: []});
    const [genderData, genderDataLoading, genderDataError] = useFetch(`${process.env.REACT_APP_BASE_URL}/api/Values`, compCode);

    useEffect(() => {
        const getStates = async () => {
            loaderAction(true);
            const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/Values/1`);
            loaderAction(false);
            let states = res.data.map(i => ({Description: i.Description, CodeId: i.CodeId}));
            setStatesList(states);
        }
        getStates();
    },[loaderAction])

    useEffect(() => {
        if (modalData.editId) {
            const item = userInfo.MembersList.AccPartyMemberMasterList.find(i => i.MemberId === modalData.editId);
            console.log(item);
            let newItem = {
                Salutation: item.Salutation ? item.Salutation : '',
                MemberName : item.MemberName ? item.MemberName : '',
                EncCompanyId: item.EncCompanyId ? item.EncCompanyId : userInfo.EncCompanyId,
                RegMob1: userInfo.RegMob1,
                Gender: item.Gender ? item.Gender : '',
                GenderDesc: item.GenderDesc ? item.GenderDesc : '',
                Address: item.Address ? item.Address : '',
                Age: item.Age ? item.Age : 0,
                AgeMonth: item.AgeMonth ? item.AgeMonth : 0,
                AgeDay: item.AgeDay ? item.AgeDay : 0,

                State: item.State ? item.State : '',
                City: item.City ? item.City : '',
                Pin: item.Pin ? item.Pin : '',
                Landmark: item.Landmark ? item.Landmark : '',

                IsDefault: item.IsDefault,
                
                ParentUserId: item.ParentUserId,
                MemberId: item.MemberId,
                MemberTypeId : item.MemberTypeId ? item.MemberTypeId : '',
                UserType: item.UserType ? item.UserType : 'PATIENT',
                UID: item.UID ? item.UID : '',
                
                DOB: new Date(item.DOB).toLocaleDateString('fr-CA'),
                IsDOBCalculated: item.IsDOBCalculated ? item.IsDOBCalculated : '',
                Aadhaar: item.Aadhaar ? item.Aadhaar : '',
                ParentAadhaar1: item.ParentAadhaar1 ? item.ParentAadhaar1 : '',
                ParentAadhaar2: item.ParentAadhaar2 ? item.ParentAadhaar2 : '',
                RelationShipWithHolder: item.RelationShipWithHolder ? item.RelationShipWithHolder : '',
                Mobile: item.Mobile ? item.Mobile : '',
                Country: 1
            }
            console.log(newItem);
            setMemberData(newItem);
        }
    }, [modalData])

    const [memberData, setMemberData] = useState({
        Salutation: '',
        MemberName : '',
        EncCompanyId: userInfo.EncCompanyId,    
        RegMob1: userInfo.RegMob1,
        Gender: '',
        GenderDesc: '',
        Address: '',
        Age: '',
        AgeMonth: '',
        AgeDay: '',             

        State: '3',
        City: '',
        Pin: '',
        Landmark: '',

        ParentUserId: userInfo.UserId,
        MemberId: 0,
        MemberTypeId : 0,
        UserType: userInfo.UserType,
        UID: '',
        
        DOB: new Date().toLocaleDateString('fr-CA'),
        IsDOBCalculated: 'N',
        Aadhaar: '',
        ParentAadhaar1: '',
        ParentAadhaar2: '',
        RelationShipWithHolder: '',
        Mobile: '',
        Country: 1
    })

    const handleMemberFormSubmit = (e) => {
        e.preventDefault();
        console.log(memberData);
        makeAddMemberRequest(memberData);
    }

    const makeAddMemberRequest = async (params) => {
        console.log(params);
        loaderAction(true);
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/member`, params);
        loaderAction(false);
        if (res.status === 200) {
          // setShowAlert(true);
          getMembersList(compCode, userInfo.UserId);
          modalAction('MEMBER_MODAL', false);
        } else {
          alert('Something went wrong, try again later.');
        }
    }


    const handleMemberInput = (e) => {
        const { name, value } = e.target;
        setMemberData(pre => ({...pre, [name]: value}));  
    }

    const toggleGender = (e) => {
        let val = e.target.value;
        let female = ['Ms', 'Mrs', 'Miss'];
        if (female.includes(val)) {
            setMemberData(preValue => {
                return {...preValue, Gender: 105, GenderDesc: 'Female'};
            });
        } else {
            setMemberData(preValue => {
                return {...preValue, Gender: 104, GenderDesc: 'Male'};
            });
        }
    }

    const getMembersList = async (companyCode, userId) => {
        if (!userId) return;
        try {      
          loaderAction(true);
          const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/member/Get?UserId=${userId}&CID=${companyCode}`, {});
          if (res.data) {
            userInfoAction({MembersList: res.data});
          }
        } catch (error) {
          alert('Something went wrong please Refresh or try after some time.');
        }
        loaderAction(false);
    }

    const handleDate = (props) => {
        const { Age, AgeMonth, AgeDay, currField, currValue }  = props;
    
        if (currField === 'Age') {
          if (currValue !== '' && AgeDay !== '' && AgeMonth !== '') {
            const calculatedDOB = createDate(AgeDay, AgeMonth, currValue);
            setMemberData(pre => ({...pre, DOB: calculatedDOB, IsDOBCalculated: 'Y'}));
          }
        } else if (currField === 'AgeDay') {
          if (Age !== '' && currValue !== '' && AgeMonth !== '') {
            const calculatedDOB = createDate(currValue, AgeMonth, Age);
            setMemberData(pre => ({...pre, DOB: calculatedDOB, IsDOBCalculated: 'Y'}));
          }
        } else if (currField === 'AgeMonth') {
          if (Age !== '' && AgeDay !== '' && currValue !== '') {
            const calculatedDOB = createDate(AgeDay, currValue, Age);
            setMemberData(pre => ({...pre, DOB: calculatedDOB, IsDOBCalculated: 'Y'}));
          }
        }
    }
    
    const handleNumberInputsWithDate = (e, setStateName) => {
        const {name, value} = e.target;
        const re = /^[0-9\b]+$/;
        if (value === '' || re.test(value)) {
            setStateName(preValue => {
                return {...preValue, [name]: value};
            });
            let currValues = { Age: memberData.Age, AgeMonth: memberData.AgeMonth, AgeDay: memberData.AgeDay, currField: name, currValue: value };
            handleDate(currValues);
        }
    }

    
    // useEffect(() => {
    //     const handleDate = () => {
    //         if (memberData.Age && memberData.AgeDay && memberData.AgeMonth) {
    //             const calculatedDOB = createDate(memberData.AgeDay, memberData.AgeMonth, memberData.Age);
    //             setMemberData(pre => ({...pre, DOB: calculatedDOB, IsDOBCalculated: 'Y'}));
    //         }
    //     }
    //     handleDate();
    // }, [memberData.Age,  memberData.AgeDay, memberData.AgeMonth])

    return (
        <div className="row" id="divEnqDataContent">
            <form className="bg-white rounded pt-2" onSubmit={handleMemberFormSubmit} id="registrationForm">
                <div className="col-md-12 pt-1">
                    <i className='bx bx-x-circle float-right-corner' style={{top: '0.5em', right: '0.4em', fontSize: '1.5em', transform: 'none'}} onClick={() => modalAction('MEMBER_MODAL', false)}></i>
                    <div>
                        <h4 className="card-title position-relative">
                            <span className="side-marker"></span>
                            <span className="bg-white pe-2">Registration Details</span>
                        </h4>
                        <div className="row gx-1 gx-md-2">
                            <div className="col-6">
                                <div className="form-group form-focus focused" id="lblMobile1">
                                    <label className="focus-label"><b className='text-danger'>* </b>Mobile Number</label>
                                    <input name="RegMob1" readOnly value={memberData.RegMob1} required className="form-control floating" tabIndex={1} id="txtMobileNo1" maxLength={10} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group form-focus focused" id="lblMobile1">
                                    <label className="focus-label"><b className='text-danger'>* </b>Aadhaar Number</label>
                                    <input name="Aadhaar" value={memberData.Aadhaar} onChange={(e) => handleNumberInputs(e, setMemberData)} required className="form-control floating" tabIndex={1} id="txtMobileNo1" maxLength={12} />
                                </div>
                            </div>
                        </div>
                        {!memberData.Aadhaar && <div className="row gx-1 gx-md-2">
                            <div className="col-6">
                                <div className="form-group form-focus focused" id="lblMobile1">
                                    <label className="focus-label"><b className='text-danger'>* </b>Father's Aadhaar</label>
                                    <input name="ParentAadhaar1" value={memberData.ParentAadhaar1} onChange={(e) => handleNumberInputs(e, setMemberData)} required className="form-control floating" tabIndex={1} id="txtMobileNo1" maxLength={12} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group form-focus focused" id="lblMobile1">
                                    <label className="focus-label"><b className='text-danger'>* </b>Mother's Aadhaar</label>
                                    <input name="ParentAadhaar2" value={memberData.ParentAadhaar2} onChange={(e) => handleNumberInputs(e, setMemberData)} required className="form-control floating" tabIndex={1} id="txtMobileNo1" maxLength={12} />
                                </div>
                            </div>
                        </div>}
                        <div className="row gx-1 gx-md-2">
                            <div className="col-6">
                                <div className="form-group form-focus focused" id="lblMobile1">
                                    <label className="focus-label"><b className='text-danger'>* </b>Alternate Mobile</label>
                                    <input name="Mobile" value={memberData.Mobile} onChange={(e) => handleNumberInputs(e, setMemberData)} className="form-control floating" tabIndex={1} id="txtMobileNo1" maxLength={10} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group form-focus focused" id="lblMobile1">
                                    <label className="focus-label"><b className='text-danger'>* </b>Relation</label>
                                    <input name="RelationShipWithHolder" value={memberData.RelationShipWithHolder} onChange={handleMemberInput} required className="form-control floating" tabIndex={1} id="txtMobileNo1" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <h4 className="card-title position-relative">
                            <span className="side-marker"></span>
                            <span className="bg-white pe-2">Personal Information</span>
                        </h4>
                        <div className="row gx-1 gx-md-2">
                            <div className="col-4">
                                <div className="form-group form-focus focused">
                                    <label className="focus-label"><b className='text-danger'>* </b>Salutation</label>
                                    <select name="Salutation" value={memberData.Salutation} required onChange={(e) => {handleMemberInput(e); toggleGender(e);}} id="ddlSalutation" tabIndex={1} className="form-control">
                                    <option value="">-Select-</option>
                                    <option value="Dr">Dr</option>
                                    <option value="Mr">Mr</option>
                                    <option value="Ms">Ms</option>
                                    <option value="Mrs">Mrs</option>
                                    <option value="Miss">Miss</option>
                                    <option value="BabyOf">Baby Of</option>
                                    <option value="Master">Master</option>
                                    <option value="Baby">Baby</option>
                                    <option value="Md">Md.</option>
                                    <option value="Prof">Prof.</option>
                                    <option value="Rev">Rev.</option>
                                    <option value="Sk">Sk.</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-8">
                                <div className="form-group form-focus focused">
                                    <label className="focus-label"><b className='text-danger'>* </b>Name</label>
                                    <input name="MemberName" value={memberData.MemberName} onChange={handleMemberInput} className="form-control floating" tabIndex={1} type="text" required/>
                                </div>
                            </div>

                            <div className="col-3">
                                <div className="form-group form-focus focused">
                                    <label className="focus-label"><b className='text-danger'>* </b>Gender</label>
                                    <select name="Gender" value={memberData.Gender} onChange={handleMemberInput} required tabIndex={1} className="form-control floating">
                                    <option value="">-Select-</option>
                                    {genderData.map(item => (<option key={item.CodeId} value={item.CodeId}>{item.Description}</option>))}
                                    </select>
                                    <input type="hidden" defaultValue id="hdnGender" />
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group form-focus focused">
                                    <label className="focus-label"><b className='text-danger'>* </b>DOB</label>
                                    <DatePicker 
                                        selected={new Date(memberData.DOB)}
                                        // onChange={(date) => setMemberData(pre => ({ ...pre, DOB: date ? date.toISOString().substr(0, 10) : new Date().toISOString().substr(0, 10)}))}
                                        onChange={(date) => setMemberData(pre => ({ ...pre, IsDOBCalculated: 'N', Age: getDuration(date?.toLocaleDateString('fr-CA')).years, AgeMonth: getDuration(date?.toLocaleDateString('fr-CA')).months, AgeDay: getDuration(date?.toLocaleDateString('fr-CA')).days, DOB: date ? date.toLocaleDateString('fr-CA') : new Date().toLocaleDateString('fr-CA')}))}
                                        showYearDropdown
                                        dateFormatCalendar="MMMM"
                                        yearDropdownItemNumber={100}
                                        scrollableYearDropdown
                                        className="form-control"
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="form-group form-focus focused">
                                    <label className="focus-label"><b className='text-danger'>* </b>Years</label>
                                    <input name="Age" value={memberData.Age} onChange={(e) => handleNumberInputsWithDate(e, setMemberData)} required className="form-control floating" tabIndex={1} type='text' maxLength={2} id="txtPtAge" />
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="form-group form-focus focused">
                                    <label className="focus-label">Months</label>
                                    <input name="AgeMonth" value={memberData.AgeMonth} onChange={(e) => handleNumberInputsWithDate(e, setMemberData)} className="form-control floating" tabIndex={1} type='text' maxLength={2} id="txtPtAgeMonth"/>
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="form-group form-focus focused">
                                    <label className="focus-label">Days</label>
                                    <input name="AgeDay" value={memberData.AgeDay} onChange={(e) => handleNumberInputsWithDate(e, setMemberData)} className="form-control floating" tabIndex={1} type='text' maxLength={2} id="txtPtAgeDay"/>
                                </div>
                            </div>
                        </div>
                        <h4 className="card-title position-relative">
                            <span className="side-marker"></span>
                            <span className="bg-white pe-2">Address Details</span>
                        </h4>
                        <div className="row gx-1 gx-md-2">
                            <div className="col-6">
                                <div className="form-group form-focus focused">
                                    <label className="focus-label">Apartment / Flat no.</label>
                                    <input name="Landmark" value={memberData.Landmark} onChange={handleMemberInput} className="form-control floating" tabIndex={1} autoComplete="off"/>
                                </div>
                            </div>                      
                            <div className="col-6">
                                <div className="form-group form-focus focused">
                                    <label className="focus-label"><b className='text-danger'>* </b>Address</label>
                                    <input name="Address" value={memberData.Address} onChange={handleMemberInput} required className="form-control floating" tabIndex={1} type="text" />
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group form-focus focused">
                                    <label className="focus-label"><b className='text-danger'>* </b>State</label>
                                    <select name="State" value={memberData.State} onChange={handleMemberInput} required tabIndex={1} className="form-control floating">
                                        <option value="">-Select-</option>
                                        {statesList.map(item => {
                                            return (
                                            <option key={item.CodeId} value={parseInt(item.CodeId)}>{item.Description}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group form-focus focused">
                                    <label className="focus-label"><b className='text-danger'>* </b>City</label>
                                    <input name="City" value={memberData.City} onChange={handleMemberInput} required className="form-control floating" tabIndex={1} type='text'/>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group form-focus focused">
                                    <label className="focus-label"><b className='text-danger'>* </b>Pin Code</label>
                                    <input name="Pin" value={memberData.Pin} onChange={(e) => handleNumberInputs(e, setMemberData)} required className="form-control floating" tabIndex={1} type='text' maxLength={6} />
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group form-focus focused">
                                    <label className="focus-label"><b className='text-danger'>* </b>Country</label>
                                    {/* <input name="Country" value={memberData.country} onChange={handleMemberInput} className="form-control floating" tabIndex={1} type="text" /> */}
                                </div>
                            </div>
                        </div>
  
                        <button type="submit" className="btn btn-primary d-block btnSave mx-auto fw-bold" style={{width: "10rem"}} tabIndex={1}>SUBMIT</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

const mapStateToMemberForm = (state) => {
    return { compCode: state.compCode, userInfo: state.userInfo };
}
  
export default connect(mapStateToMemberForm, { userInfoAction, loaderAction, modalAction })(MemberForm);
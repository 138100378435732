import { connect } from "react-redux";
import { modalAction } from "../../actions";
import { CustomModal, CustomOffcanvas } from "../utilities";
import TimeSchedule from "./timeSchedule";
import MemberForm from "./memberForm";
// import MemberForm from "./testModal";
import DocDetailsModal from "./docProfileModal";

const Modals = ({ modals, modalAction }) => {
    return (
        <>
            <CustomModal isActive={modals.SCHEDULE_MODAL.state} fullscreen={false} handleClose={modalAction} customClass='schedule-modal' name={'SCHEDULE_MODAL'} child={<TimeSchedule/>}/>
            {/* <CustomModal isActive={modals.PRINT_MODAL} handleClose={modalAction} customClass='print-canvas' child={<InvoicePrint/>} /> */}
            <CustomModal isActive={modals.MEMBER_MODAL.state} handleClose={modalAction} customClass='member-modal' child={<MemberForm modalData={modals.MEMBER_MODAL.data}/>} name={'MEMBER_MODAL'}/>
            {/* <ModalComponent isActive={modals.QUICKVIEW_MODAL} child={<QuickViewModal/>}/>
            <ModalComponent isActive={modals.USER_INFO_MODAL} child={<UserInfoModal/>}/>
            <ModalComponent isActive={modals.ORDER_SUCCESS_MODAL} child={<OrderSuccessModal/>}/>
            <ModalComponent isActive={modals.ORDER_CANCELLED_MODAL} child={<OrderCancelledModal/>}/> */}
            <CustomOffcanvas isActive={modals.DOC_DETAILS_MODAL.state} name={'DOC_DETAILS_MODAL'} handleClose={modalAction} customClass='doc-details-modal' child={<DocDetailsModal/>}/>
        </>
    )
}

const mapStateToModals = (state) => {
    return { modals: state.modals };
}

export default connect(mapStateToModals, {modalAction})(Modals);
import { navigateToProfile } from "../utilities";
import { connect } from "react-redux";


const Profile = ({ userInfo }) => {

    return navigateToProfile(userInfo.UserType);

}

const mapStateToProfile = (state) => {
    return { userInfo: state.userInfo }
}

export default connect(mapStateToProfile, {})(Profile);